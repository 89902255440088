import { User } from '../types/user';
import baseApi from './baseApi';

export const fetchLoggedInUserInfo = async (): Promise<User> => {
  try {
    const response = await baseApi.get<User>(
      '/business/authentication/GetLoggedInUserInfo'
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
