import {
  PlacementTestInvitePayload,
  BulkPlacementTestInvitePayload,
} from '../types/placementTest';
import baseApi from './baseApi';

export const sendTestInvite = async (
  email: string,
  languageId: number,
  businessUserId: number,
  businessCandidateId: number
): Promise<void> => {
  const payload: PlacementTestInvitePayload = {
    Email: email,
    HasGivenMarketingPermission: false,
    LanguageId: languageId,
    BusinessUserId: businessUserId,
    BusinessCandidateId: businessCandidateId,
  };

  await baseApi.post('/placementTests/invite', payload);
};

export const sendBulkTestInvite = async (
  businessUserId: number,
  invites: PlacementTestInvitePayload[]
): Promise<void> => {
  const payload: BulkPlacementTestInvitePayload = {
    BusinessUserId: businessUserId,
    Invites: invites,
  };

  await baseApi.post('/placementTests/BulkInvite', payload);
};

export const sendReminder = async (testId: any) => {
  try {
    const response = await baseApi.post(
      `/placementTests/SendBusinessReminder/${testId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const cancelAndDeleteTest = async (testId: any) => {
  try {
    const response = await baseApi.delete(
      `/placementTests/CancelAndDeleteTest/${testId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
