import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, TextField, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { resetPassword } from '../../../api/passwordResetApi';
import { ResetPasswordData } from '../../../types/resetPassword';
import { useLoginStyles } from '../Login/styles';

const ResetPasswordPage = () => {
  const { uid } = useParams<string>();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const classes = useLoginStyles();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      toast.error('Passwords do not match.');
      return;
    }

    try {
      await resetPassword({ uid, password } as ResetPasswordData);
      toast.success('Your password has been reset successfully.');
      navigate('/login');
    } catch (error) {
      console.error('Reset password error:', error);
      toast.error('Error resetting password. Please try again.');
    }
  };

  return (
    <Box sx={classes.mainContainer}>
      <Box sx={classes.logoSection}></Box>
      <Box sx={classes.loginFormSection}>
        <Box component="form" sx={classes.formStyle} onSubmit={handleSubmit}>
          <Typography sx={classes.heading}>Set a New Password</Typography>
          <TextField
            label="New Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            fullWidth
            margin="normal"
            sx={classes.inputStyle}
          />
          <TextField
            label="Confirm Password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            fullWidth
            margin="normal"
            sx={classes.inputStyle}
          />
          <Button type="submit" fullWidth sx={classes.loginButton}>
            Reset Password
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPasswordPage;
