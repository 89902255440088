import { SxProps, Theme } from '@mui/system';

export const useCandidateTestResultsModalStyles = (): Record<
  string,
  SxProps<Theme>
> => ({
  modal: {
    '& .MuiDialog-paper': {
      display: 'flex',
      flexDirection: 'column',
      padding: '24px',
      gap: '32px',
      maxHeight: '85%',
      maxWidth: '800px',
      overflowY: 'auto',
    },
  },
  dialogTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px',
    gap: '8px',
    width: '752px',
    height: '40px',
    margin: '0px',
  },
  languageName: {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '40px',
    color: '#24407E',
    display: 'flex',
    alignItems: 'center',
    width: '81px',
    height: '40px',
  },
  downloadButton: {
    padding: '8px 12px',
    width: '126px',
    height: '32px',
    background: '#FFFFFF',
    border: '1px solid #24407E',
    borderRadius: '4px',
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '14px',
    color: '#24407E',
    textTransform: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  closeIconButton: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  dialogContent: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '752px',
  },
  overallStatsSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '24px',
    width: '100%',
    marginBottom: '24px',
  },
  testQuestionSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
    width: '100%',
  },
  questionPaper: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '12px 16px',
    gap: '16px',
    width: '100%',
    background: '#FFFFFF',
    border: '1.5px solid #EFF3FA',
    borderRadius: '8px',
    '&:not(:last-of-type)': {
      marginBottom: '12px',
    },
  },
  scoreTypography: {
    color: '#6C7378',
    fontWeight: '500',
  },
  valueTypography: {
    color: '#030404',
    fontWeight: '700',
  },
});
