import { SxProps } from '@mui/system';

export const useEmailTemplatesTabStyles = (): Record<string, SxProps> => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    background: '#FFFFFF',
    borderRadius: '8px',
    minWidth: '100%',
  },
  textField: {
    '.MuiInputLabel-root': {
      color: '#030404',
      fontSize: '16px',
      fontWeight: 500,
    },
    '.MuiOutlinedInput-root': {
      background: '#FFFFFF',
      border: '1.5px solid #EFF3FA',
      borderRadius: '8px',
      '.MuiOutlinedInput-input': {
        color: '#6C7378',
        fontSize: '16px',
        fontWeight: 500,
      },
    },
  },
});
