import { RegistrationData, RegistrationResponse } from '../types/register';
import baseApi from './baseApi';

export const registerUser = async (
  userData: RegistrationData
): Promise<RegistrationResponse> => {
  try {
    const response = await baseApi.post<RegistrationResponse>(
      '/business/register',
      userData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTermsAndPrivacyUrl = () => {
  return 'https://app.nextgenplacement.org/Uploads/Terms-and-privacy.pdf';
};
