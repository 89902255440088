import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import { useFileUploadBoxStyles } from './styles';

interface FileUploadBoxProps {
  file: File | null;
  logoUrl: string | null;
  onFileChange: (file: File | null) => void;
  onFileRemove: () => void;
}

const FileUploadBox = ({
  file,
  logoUrl,
  onFileChange,
  onFileRemove,
}: FileUploadBoxProps) => {
  const [uploadedFileName, setUploadedFileName] = useState('');
  const classes = useFileUploadBoxStyles();

  useEffect(() => {
    if (file) {
      setUploadedFileName(file.name);
    } else {
      setUploadedFileName('');
    }
  }, [file, logoUrl]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newFile = event.target.files ? event.target.files[0] : null;
    setUploadedFileName(newFile ? newFile.name : '');
    onFileChange(newFile);
  };

  const handleRemove = () => {
    setUploadedFileName('');
    onFileRemove();
  };

  return (
    <>
      <Typography variant="body1" sx={classes.uploadTitle}>
        Logo
      </Typography>
      <Box sx={classes.uploadBox}>
        {uploadedFileName ? (
          <Typography variant="body2" sx={classes.fileName}>
            {uploadedFileName}
          </Typography>
        ) : logoUrl ? (
          <>
            <img
              src={logoUrl}
              alt="Logo"
              style={{ maxWidth: '100px', maxHeight: '100px' }}
            />
          </>
        ) : (
          <Box sx={classes.iconBox}>
            <PhotoCamera sx={classes.icon} />
            <Typography variant="body2" sx={classes.fileName}>
              Upload file
            </Typography>
          </Box>
        )}

        <Box sx={classes.infoBox}>
          <Typography sx={classes.fileFormatText}>
            PNG, JPEG, or GIF. Max size is 40MB.
          </Typography>
          <Box sx={classes.buttonsBox}>
            <Button
              variant="contained"
              component="label"
              sx={classes.browseButton}
            >
              Browse
              <input
                type="file"
                accept=".png,.jpg,.jpeg"
                hidden
                onChange={handleFileChange}
              />
            </Button>
            {logoUrl || uploadedFileName ? (
              <Button
                variant="outlined"
                onClick={handleRemove}
                sx={classes.removeButton}
              >
                Remove
              </Button>
            ) : null}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default FileUploadBox;
