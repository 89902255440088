import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';
import { useUserContext } from './UserContext';
import LoadingSpinner from '../components/elements/loading';

interface SettingsContextType {
  formData: SettingsFormData;
  handleFormDataChange: (tab: keyof SettingsFormData, data: any) => void;
  resetFormData: () => void;
}

const SettingsContext = createContext<SettingsContextType | undefined>(
  undefined
);

export const useSettings = () => {
  const context = useContext(SettingsContext);
  if (context === undefined) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context;
};

interface SettingsProviderProps {
  children: ReactNode;
}

export const SettingsProvider = ({ children }: SettingsProviderProps) => {
  const { user, isLoading } = useUserContext();

  const getInitialFormData = (): SettingsFormData => ({
    general: {
      logo: null,
      tradingName: user?.tradingName || '',
      legalName: user?.legalName || '',
      address: user?.address || '',
      city: user?.city || '',
      province: user?.province || '',
      country: user?.country || '',
      website: user?.website || '',
      contactPhone: user?.contactPhone || '',
      contactEmail: user?.contactEmail || '',
      legalRepresentative: user?.legalRepresentative || '',
      legalRepresentativeEmail: user?.legalRepresentativeEmail || '',
    },
    billing: {
      companyName: user?.companyName || '',
      companyVatId: user?.companyVatId || '',
      companyAddress: user?.companyAddress || '',
    },
    emailTemplates: {
      sendTestTemplate: user?.sendTestTemplate || '',
      reminderTemplate: user?.reminderTemplate || '',
    },
  });

  const [formData, setFormData] =
    useState<SettingsFormData>(getInitialFormData());

  useEffect(() => {
    if (!isLoading && user) {
      setFormData(getInitialFormData());
    }
  }, [user, isLoading]);

  const handleFormDataChange = (tab: keyof SettingsFormData, data: any) => {
    setFormData((prev) => ({ ...prev, [tab]: data }));
  };

  const resetFormData = () => {
    setFormData(getInitialFormData());
  };

  if (isLoading) return <LoadingSpinner />;

  return (
    <SettingsContext.Provider
      value={{ formData, handleFormDataChange, resetFormData }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
