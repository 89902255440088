import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Container,
  Grid,
  Paper,
  TextField,
  IconButton,
  TextFieldProps,
  InputAdornment,
} from '@mui/material';
import { Formik, Form } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { SharedLayout } from '../SharedLayout';
import { CandidateTests } from '../CandidateTests';
import { DeleteModal } from '../../elements/modals/DeleteModal';
import { SendTestModal } from '../../elements/modals/SendTestModal';
import { toast } from 'react-toastify';
import {
  cancelAndDeleteTest,
  sendReminder,
  sendTestInvite,
} from '../../../api/placementTestApi';
import { useUserContext } from '../../../contexts/UserContext';
import {
  addEditCandidate,
  deleteCandidate,
  fetchCandidate,
} from '../../../api/candidatesApi';
import { CandidateWithTests } from '../../../types/candidates';
import { useQueryClient } from 'react-query';
import PencilSquare from '../../elements/icons/PencilSquareIcon';
import CandidateTrashIcon from '../../elements/icons/CandidateTrashIcon';
import CandidatePaperIcon from '../../elements/icons/CandidatePaperIcon';
import CandidateChveronLeft from '../../elements/icons/CandidateChveronLeftIcon';
import { candidateValidationSchema } from './validations';
import { useAddEditCandidateStyles } from './styles';
import { format, isValid, parse } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import React from 'react';
import { CalendarToday } from '@mui/icons-material';

interface CustomDateInputProps
  extends Omit<TextFieldProps, 'value' | 'onChange'> {
  value: Date | null;
  onClick: () => void;
  disabled?: boolean;
}

const CustomDateInput = React.forwardRef<
  HTMLInputElement,
  CustomDateInputProps
>(({ value, onClick, disabled, ...rest }, ref) => {
  let displayValue = '';
  if (typeof value === 'string') {
    const date = parse(value, 'dd/MM/yyyy', new Date());
    displayValue = isValid(date) ? format(date, 'dd/MM/yyyy') : '';
  } else if (value instanceof Date && isValid(value)) {
    displayValue = format(value, 'dd/MM/yyyy');
  }

  return (
    <TextField
      {...rest}
      ref={ref}
      type="text"
      value={displayValue}
      onClick={onClick}
      placeholder="DD/MM/YYYY"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={onClick}>
              <CalendarToday />
            </IconButton>
          </InputAdornment>
        ),
        readOnly: true,
      }}
      variant="outlined"
      fullWidth
      size="medium"
      disabled={disabled}
    />
  );
});

CustomDateInput.displayName = 'CustomDateInput';

const AddEditCandidate = () => {
  const navigate = useNavigate();
  const styles = useAddEditCandidateStyles();
  const { user } = useUserContext();
  const queryClient = useQueryClient();
  const { id: candidateId } = useParams();
  const isEdit = Boolean(candidateId);
  const [candidate, setCandidate] = useState<CandidateWithTests | null>(null);
  const [pendingLanguageIds, setPendingLanguageIds] = useState<number[]>([]);

  const [editMode, setEditMode] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [sendTestModalOpen, setSendTestModalOpen] = useState(false);

  useEffect(() => {
    fetchAndUpdateCandidateData();
  }, [candidateId, isEdit]);

  const fetchAndUpdateCandidateData = async () => {
    if (!candidateId) return;

    try {
      const fetchedCandidate = await fetchCandidate(Number(candidateId));
      setCandidate(fetchedCandidate);

      const calcPendingLanguageIds = fetchedCandidate.tests
        .filter((test) => test.status === 'Pending')
        .map((test) => test.languageId);
      setPendingLanguageIds(calcPendingLanguageIds);
    } catch (error) {
      console.error('Error fetching candidate:', error);
      navigate('/candidates');
    }
  };

  const handleDelete = () => {
    setDeleteModalOpen(true);
  };

  const handleCancel = () => {
    navigate('/candidates');
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteCandidate(Number(candidateId));
      toast.success('Candidate deleted successfully');
      navigate('/candidates');
    } catch (error) {
      console.error(error);
      toast.error('Failed to delete candidate');
    }
  };

  const handleSendTests = () => {
    setSendTestModalOpen(true);
  };

  const onSendReminder = async (placementTestId: any) => {
    try {
      await sendReminder(placementTestId);
      toast.success('Reminder sent successfully');
    } catch (error) {
      console.error('Error sending reminder:', error);
      toast.error('Failed to send reminder');
    }
  };

  const onCancelAndDelete = async (placementTestId: any) => {
    try {
      await cancelAndDeleteTest(placementTestId);
      toast.success('Test cancelled and deleted successfully');
      queryClient.invalidateQueries('userInfo');
      fetchAndUpdateCandidateData();
    } catch (error) {
      console.error('Error cancelling and deleting test:', error);
      toast.error('Failed to cancel and delete test');
    }
  };

  const handleSendTestsConfirm = async (languageId: number) => {
    try {
      await sendTestInvite(
        candidate!.email,
        languageId,
        user!.id,
        Number(candidateId)
      );
      toast.success('Test sent successfully');
      queryClient.invalidateQueries('userInfo');
      fetchAndUpdateCandidateData();
      setSendTestModalOpen(false);
    } catch (error) {
      console.error(error);
      toast.error('Failed to send test invitation, please check your balance', {
        autoClose: 5000,
      });
      setSendTestModalOpen(true);
    }
  };

  return (
    <SharedLayout>
      <Container component="main">
        <Formik
          initialValues={{
            id: Number(candidate?.id),
            firstName: candidate?.firstName || '',
            lastName: candidate?.lastName || '',
            email: candidate?.email || '',
            phoneNumber: candidate?.phoneNumber || '',
            dateOfBirth: candidate?.dateOfBirth
              ? new Date(candidate.dateOfBirth)
              : null,
          }}
          validationSchema={candidateValidationSchema}
          enableReinitialize={true}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              const submissionData = {
                ...values,
                dateOfBirth: values.dateOfBirth
                  ? format(values.dateOfBirth, 'yyyy-MM-dd')
                  : '',
              };

              const response = await addEditCandidate(submissionData);
              toast.success('Candidate saved successfully');
              setSubmitting(false);
              if (!isEdit) {
                navigate('/candidates/edit/' + response.id);
              } else {
                setEditMode(false);
              }
            } catch (error) {
              toast.error('Failed to save candidate');
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
            resetForm,
            values,
          }) => (
            <>
              <Box sx={styles.navigationBox}>
                <IconButton onClick={() => navigate('/candidates')}>
                  <CandidateChveronLeft />
                </IconButton>
                <Typography sx={styles.navigationTypo}>Candidates</Typography>
                <Typography sx={styles.navigationSlash}>/</Typography>
                <Typography sx={styles.navigationName}>
                  {candidate?.firstName} {candidate?.lastName}
                </Typography>
              </Box>

              <Box sx={styles.mainTitle}>
                <Typography
                  component="h1"
                  variant="h5"
                  sx={styles.candidateTitle}
                >
                  {isEdit ? 'Candidate details' : 'New Candidate'}
                </Typography>

                {isEdit && !editMode ? (
                  <Box sx={styles.editButtonBox}>
                    <Button
                      variant="outlined"
                      startIcon={<PencilSquare />}
                      onClick={() => setEditMode(true)}
                      sx={{
                        ...styles.buttonOutlined,
                        borderColor: '#24407E',
                        color: '#24407E',
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<CandidateTrashIcon />}
                      onClick={handleDelete}
                      sx={{
                        ...styles.buttonOutlined,
                        borderColor: '#A73737',
                        color: '#A73737',
                      }}
                    >
                      Delete
                    </Button>
                    <Button
                      variant="contained"
                      startIcon={<CandidatePaperIcon />}
                      onClick={handleSendTests}
                      sx={styles.sendTestsButton}
                    >
                      Send Tests
                    </Button>
                  </Box>
                ) : (
                  <Box sx={styles.editModeButtonBox}>
                    {isEdit ? (
                      <Button
                        onClick={() => resetForm()}
                        sx={styles.cancelResetButton}
                      >
                        Reset Changes
                      </Button>
                    ) : (
                      <Button
                        onClick={handleCancel}
                        sx={styles.cancelResetButton}
                      >
                        Cancel
                      </Button>
                    )}

                    <Button
                      variant="contained"
                      onClick={() => handleSubmit()}
                      sx={styles.createButton}
                    >
                      {isEdit ? 'Save' : 'Create'}
                    </Button>
                  </Box>
                )}
              </Box>

              <Paper elevation={3} sx={styles.generalInfoPaper}>
                <Typography
                  component="h2"
                  paddingBottom={2}
                  variant="h6"
                  sx={styles.generalInfoTitle}
                >
                  General Info
                </Typography>
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="First Name"
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        error={touched.firstName && Boolean(errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                        fullWidth
                        disabled={!editMode && isEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Last Name"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        error={touched.lastName && Boolean(errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                        fullWidth
                        disabled={!editMode && isEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Email"
                        name="email"
                        type="email"
                        value={values.email}
                        onChange={handleChange}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                        fullWidth
                        disabled={!editMode && isEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Phone Number"
                        name="phoneNumber"
                        value={values.phoneNumber}
                        onChange={handleChange}
                        error={
                          touched.phoneNumber && Boolean(errors.phoneNumber)
                        }
                        helperText={touched.phoneNumber && errors.phoneNumber}
                        fullWidth
                        disabled={!editMode && isEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <DatePicker
                        selected={values.dateOfBirth}
                        onChange={(date: Date) =>
                          setFieldValue('dateOfBirth', date)
                        }
                        dateFormat="dd/MM/yyyy"
                        dropdownMode="select"
                        showMonthDropdown
                        disabled={!editMode && isEdit}
                        showYearDropdown
                        customInput={
                          <CustomDateInput
                            value={values.dateOfBirth}
                            label="Date Of Birth"
                            disabled={!editMode && isEdit}
                            onClick={() => {}}
                            error={
                              touched.dateOfBirth && Boolean(errors.dateOfBirth)
                            }
                            helperText={
                              touched.dateOfBirth
                                ? errors.dateOfBirth
                                : undefined
                            }
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </Form>
              </Paper>
              {isEdit && candidate && (
                <Paper elevation={3}>
                  <CandidateTests
                    candidateName={`${candidate.firstName} ${candidate.lastName}`}
                    tests={candidate.tests}
                    onSendReminder={onSendReminder}
                    onCancelAndDelete={onCancelAndDelete}
                  />
                </Paper>
              )}
            </>
          )}
        </Formik>
        <DeleteModal
          isOpen={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onDelete={handleDeleteConfirm}
          title={'Are you sure you want to delete the candidate?'}
          description={
            'This action will delete the candidate and all the related data, including tests and test results and cannot be undone'
          }
        />
        <SendTestModal
          isOpen={sendTestModalOpen}
          onClose={() => setSendTestModalOpen(false)}
          onSendTest={(languageId) => handleSendTestsConfirm(languageId)}
          pendingLanguageIds={pendingLanguageIds}
          numberOfCandidates={1}
        />
      </Container>
    </SharedLayout>
  );
};

export default AddEditCandidate;
