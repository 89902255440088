import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { useDeleteModalStyles } from './styles';

interface DeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  title: string;
  description: string;
}

const DeleteModal = ({
  isOpen,
  onClose,
  onDelete,
  title,
  description,
}: DeleteModalProps) => {
  const styles = useDeleteModalStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      PaperProps={{ sx: styles.dialogPaper }}
    >
      <DialogTitle sx={styles.dialogTitle}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={styles.dialogContentText}>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={styles.dialogActions}>
        <Button onClick={onClose} sx={styles.button.primary}>
          Cancel
        </Button>
        <Button onClick={onDelete} sx={styles.button.secondary}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
