import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Tab,
  Tabs,
  Typography,
  AppBar,
  Paper,
} from '@mui/material';
import { SharedLayout } from '../SharedLayout';
import GeneralTab from './GeneralTab/GeneralTab';
import BillingTab from './BillingTab/BillingTab';
import EmailTemplatesTab from './EmailTemplatesTab/EmailTemplatesTab';
import { useSettings } from '../../../contexts/SettingsContext';
import { changePassword, updateSettings } from '../../../api/settingsApi';
import { toast } from 'react-toastify';
import { useSettingsStyles } from './styles';
import { useQueryClient } from 'react-query';
import IntegrationTab from './IntegrationTab/IntegrationTab';

function TabPanel(props: {
  children?: React.ReactNode;
  value: number;
  index: number;
}) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpanel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
      style={{ width: '100%' }}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `settings-tab-${index}`,
    'aria-controls': `settings-tabpanel-${index}`,
  };
}

const Settings = () => {
  const { formData, resetFormData } = useSettings();
  const [formErrors, setFormErrors] = useState<SettingsFormErrors>({});
  const [tabValue, setTabValue] = useState(0);
  const queryClient = useQueryClient();
  const classes = useSettingsStyles();

  const handleChangeTab = (
    event: React.SyntheticEvent,
    newValue: number
  ): void => {
    setTabValue(newValue);
  };

  const handleSubmit = async () => {
    try {
      if (!validateForm()) {
        toast.error('Please fill in all required fields.');
        return;
      }

      await updateSettings(formData);
      queryClient.invalidateQueries('userInfo');
      toast.success('Settings updated successfully');
    } catch (error) {
      console.error('Failed to update settings', error);
      toast.error('Failed to update settings');
    }
  };

  const handleChangePassword = async (
    currentPassword: string,
    newPassword: string
  ) => {
    try {
      await changePassword(currentPassword, newPassword);
      toast.success('Password changed successfully');
    } catch (error) {
      console.error('Password change failed', error);
      toast.error('Failed to change password');
    }
  };

  const handleResetChanges = () => {
    resetFormData();
    toast.info('Changes have been reset');
  };

  const validateForm = () => {
    const errors: SettingsFormErrors = {};

    if (!formData.general.tradingName.trim())
      errors.tradingName = 'Trading Name is required';
    if (!formData.general.legalName.trim())
      errors.legalName = 'Legal Name is required';
    if (!formData.general.address.trim())
      errors.address = 'Address is required';
    if (!formData.general.city.trim()) errors.city = 'City is required';
    if (!formData.general.contactPhone.trim())
      errors.contactPhone = 'Contact Phone is required';
    if (!formData.general.country) errors.country = 'Country is required';
    if (!formData.general.contactEmail.trim())
      errors.contactEmail = 'Contact Email is required';

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  return (
    <SharedLayout>
      <Container sx={classes.container}>
        <Box sx={classes.header}>
          <Typography variant="h4" sx={classes.title}>
            Settings
          </Typography>
          <Box sx={classes.buttonBox}>
            <Button onClick={handleResetChanges} sx={classes.resetButton}>
              Reset changes
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={classes.saveButton}
            >
              Save
            </Button>
          </Box>
        </Box>
        <Paper sx={classes.paper}>
          <AppBar position="static" color="default" sx={classes.appBar}>
            <Tabs
              value={tabValue}
              onChange={handleChangeTab}
              aria-label="settings tabs"
              variant="fullWidth"
              sx={classes.tabs}
            >
              <Tab label="General" {...a11yProps(0)} />
              <Tab label="Billing" {...a11yProps(1)} />
              <Tab label="E-mail templates" {...a11yProps(2)} />
              <Tab label="Integration" {...a11yProps(3)} />
            </Tabs>
          </AppBar>
          <TabPanel value={tabValue} index={0}>
            <GeneralTab
              handleChangePassword={handleChangePassword}
              formErrors={formErrors}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <BillingTab />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <EmailTemplatesTab />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <IntegrationTab />
          </TabPanel>
        </Paper>
      </Container>
    </SharedLayout>
  );
};

export default Settings;
