import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { deleteCandidate, uploadCandidatesCsv } from '../api/candidatesApi';

export const useCandidateMutations = () => {
  const queryClient = useQueryClient();

  const deleteMutation = useMutation(deleteCandidate, {
    onSuccess: () => {
      queryClient.invalidateQueries('candidates');
      toast.success('Candidate deleted successfully');
    },
    onError: (error) => {
      console.error(error);
      toast.error('Failed to delete candidate');
    },
  });

  const uploadMutation = useMutation(uploadCandidatesCsv, {
    onSuccess: () => {
      queryClient.invalidateQueries('candidates');
      queryClient.invalidateQueries('importResults');
      toast.success('Candidates uploaded successfully');
    },
    onError: (error) => {
      console.error(error);
      toast.error('Failed to upload candidates');
    },
  });

  return {
    deleteMutation,
    uploadMutation,
  };
};
