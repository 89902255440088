import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

export const sharedLayoutStyles = (
  theme: Theme
): Record<string, SxProps<Theme>> => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#EFF3FA',
    minHeight: '100vh',
  },
  appBar: {
    background: '#EFF3FA',
    padding: { xs: '8px 16px', sm: '16px 32px', md: '16px 72px' },
    alignItems: 'flex-start',
    boxShadow: 'none',
  },
  toolbar: {
    width: '100%',
    minHeight: '64px',
    justifyContent: 'space-between',
    gap: '8px',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  button: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#24407E',
    padding: '0px 8px',
    textTransform: 'none',
    '&:hover': { borderBottom: '1px solid #A73737' },
    margin: '4px',
  },
  tokenButton: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'auto',
    minWidth: '117px',
    height: '48px',
    backgroundColor: 'rgba(36, 64, 126, 0.08)',
    border: '1px solid rgba(36, 64, 126, 0.16)',
    borderRadius: '8px',
    padding: '8px 12px',
    gap: '4px',
    color: '#24407E',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'rgba(36, 64, 126, 0.1)',
    },
    margin: '4px',
  },
  purchaseButton: {
    width: '171px',
    height: '48px',
    backgroundColor: '#24407E',
    borderRadius: '8px',
    color: '#FFFFFF',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'rgba(36, 64, 126, 0.9)',
    },
    margin: '4px',
  },
  profileButton: {
    width: '171px',
    height: '48px',
    background: '#FFFFFF',
    border: '1.5px solid #E7ECEE',
    borderRadius: '8px',
    padding: '10px 16px',
    gap: '8px',
    color: '#141414',
    textTransform: 'none',
    '&:hover': {
      background: '#f0f0f0',
    },
    margin: '4px',
  },
  menu: {
    '.MuiPaper-root': {
      width: '200px',
      maxHeight: 'none',
      background: '#FFFFFF',
      border: '1px solid #E7ECEE',
      borderRadius: '8px',
    },
  },
  menuItem: {
    justifyContent: 'center',
    fontSize: '14px',
    fontWeight: 500,
    color: '#141414',
    width: '100%',
  },
  signOutMenuItem: {
    justifyContent: 'center',
    fontSize: '14px',
    fontWeight: 500,
    color: '#C43B52',
    width: '100%',
  },
  footer: {
    textAlign: 'center',
    padding: '16px',
  },
});
