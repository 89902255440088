import { SxProps, Theme } from '@mui/system';

export const useRegisterStyles = (): Record<string, SxProps<Theme>> => ({
  mainContainer: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#EFF3FA',
  },
  logoContainer: {
    width: { md: '30%' },
    flexShrink: 0,
    backgroundColor: '#0E182F',
    padding: { xs: '20px', md: '40px 56px' },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  topLogo: {
    width: '120px',
    height: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bottomLogo: {
    width: '89px',
    height: '74px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'center',
    padding: { xs: '20px', sm: '40px', md: '80px 144px' },
    gap: '24px',
  },
  heading: {
    mb: 2,
    color: '#24407E',
    fontWeight: 700,
    fontSize: { xs: '24px', sm: '28px', md: '32px' },
    lineHeight: '48px',
  },
  formField: {
    '& .MuiInputBase-input': {
      fontSize: '14px',
    },
    '& .MuiInputLabel-root': {
      fontSize: '14px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#EFF3FA',
    },
    '& .Mui-error': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#f44336',
      },
    },
    '& .MuiFormHelperText-root.Mui-error': {
      backgroundColor: '#EFF3FA',
      margin: 0,
      color: '#f44336',
    },
    marginY: '10px',
    bgcolor: 'background.paper',
    '& .MuiFormHelperText-root': {
      minHeight: '1.5em',
    },
  },
  submitButton: {
    mt: 3,
    mb: 2,
    bgcolor: '#24407E',
    '&:hover': {
      bgcolor: '#1c2532',
    },
    fontSize: '14px',
    fontWeight: 700,
    textTransform: 'none',
    border: '1px solid #24407E',
    borderRadius: '8px',
  },
  loginButton: {
    bgcolor: '#FFFFFF',
    color: '#24407E',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 700,
    border: '1px solid #24407E',
    borderRadius: '8px',
    '&:hover': { bgcolor: '#f0f0f0' },
    padding: '12px 20px',
  },
  linkStyle: {
    display: 'block',
    textAlign: 'center',
    mt: 2,
  },
});
