import React, { useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { useState, useEffect } from 'react';
import {
  fetchCandidateTestResults,
  fetchCandidateTestResultsPdf,
} from '../../../../api/candidatesApi';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { TestResultsSummary } from './types';
import DownloadIcon from '../../icons/DownloadIcon';
import { Close } from '@mui/icons-material';
import LoadingSpinner from '../../loading';
import { useCandidateTestResultsModalStyles } from './styles';

const CandidateTestResultsModal = ({
  testId,
  isOpen,
  candidateName,
  onClose,
}: {
  testId: number;
  isOpen: boolean;
  candidateName: string;
  onClose: () => void;
}) => {
  const classes = useCandidateTestResultsModalStyles();
  const [testResultsSummary, setTestResultsSummary] =
    useState<TestResultsSummary | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetchCandidateTestResults(testId)
      .then((data) => {
        setTestResultsSummary(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Failed to fetch test results:', error);
        setIsLoading(false);
      });
  }, [testId]);

  const handleDownloadPdf = async () => {
    setIsDownloadingPdf(true);
    try {
      const blob = await fetchCandidateTestResultsPdf(testId, candidateName);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `TestResults_${candidateName}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading the PDF:', error);
    } finally {
      setIsDownloadingPdf(false);
    }
  };

  const scores = useMemo(
    () => ({
      'Overall Level': testResultsSummary?.overallScoreTitle ?? '',
      'Use of Language': `${testResultsSummary?.useOfLanguagePercentage ?? ''}%`,
      Reading: testResultsSummary?.readingScore ?? '',
      Listening: testResultsSummary?.listeningScore ?? '',
    }),
    [testResultsSummary]
  );

  if (isLoading) return <LoadingSpinner />;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={classes.modal}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle sx={classes.dialogTitle}>
        <Typography variant="h6" sx={classes.languageName}>
          {testResultsSummary?.languageName}
        </Typography>
        <Button
          startIcon={!isDownloadingPdf ? <DownloadIcon /> : null}
          onClick={handleDownloadPdf}
          disabled={isDownloadingPdf}
          sx={classes.downloadButton}
        >
          {isDownloadingPdf ? <CircularProgress size={18} /> : 'Download PDF'}
        </Button>

        <IconButton onClick={onClose} sx={classes.closeIconButton}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={classes.dialogContent}>
        <Box sx={{ width: '100%' }}>
          <Box sx={classes.overallStatsSection}>
            {Object.entries(scores).map(([label, value]) => (
              <Box key={label} sx={{ width: '170px' }}>
                <Typography variant="subtitle2" sx={classes.scoreTypography}>
                  {label.charAt(0).toUpperCase() + label.slice(1)}:
                </Typography>
                <Typography variant="body2" sx={classes.valueTypography}>
                  {value}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box sx={classes.testQuestionSection}>
            {testResultsSummary?.testQuestions.map((result, index) => (
              <Paper key={index} sx={classes.questionPaper}>
                <ListItemIcon>
                  {result.isAnsweredCorrectly ? (
                    <CheckCircleOutlineIcon color="success" />
                  ) : (
                    <HighlightOffIcon color="error" />
                  )}
                </ListItemIcon>
                <ListItemText primary={result.questionText} />
              </Paper>
            ))}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CandidateTestResultsModal;
