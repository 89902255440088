import React from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import { DashboardData } from '../../../../types/dashboard';
import { User } from '../../../../types/user';
import { useDashboardCardStyles } from './cardStyles';
import EuroIcon from '../../../elements/icons/EuroIcon';
import CheckBadgeIcon from '../../../elements/icons/CheckBadgeIcon';
import ElipsisHorizontalIcon from '../../../elements/icons/ElipsisHorizontalIcon';
import ShoppingIcon from '../../../elements/icons/ShoppingIcon';

interface DashboardCardsProps {
  user: User | undefined;
  dashboardData: DashboardData;
  setPaymentModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DashboardCards = ({
  user,
  dashboardData,
  setPaymentModalOpen,
}: DashboardCardsProps) => {
  const styles = useDashboardCardStyles();

  return (
    <Grid container spacing={3}>
      {[
        {
          icon: <EuroIcon />,
          title: user?.tokenBalance ?? 'N/A',
          subtitle: 'Token balance',
          bgColorIconBox: '#EFF3FA',
          iconColor: '#24407E',
        },
        {
          icon: <CheckBadgeIcon />,
          title: dashboardData.completedTestsCount,
          subtitle: 'Tests completed to date',
          bgColorIconBox: '#EFF3FA',
          iconColor: '#24407E',
        },
        {
          icon: <ElipsisHorizontalIcon />,
          title: dashboardData.pendingTestsCount,
          subtitle: 'Tests pending',
          bgColorIconBox: '#EFF3FA',
          iconColor: '#24407E',
        },
        {
          icon: <ShoppingIcon />,
          title: 'Purchase tokens',
          subtitle: 'Click here to get more tokens',
          bgColorIconBox: '#0E182F',
          iconColor: '#FFFFFF',
          bgCard: '#24407E',
          onCardClick: () => setPaymentModalOpen(true),
        },
      ].map((card, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Paper
            elevation={0}
            sx={styles.paper(card)}
            onClick={card.onCardClick}
          >
            <Box sx={styles.iconBox(card)}>
              {React.cloneElement(card.icon, {
                sx: { color: card.iconColor, width: 24, height: 24 },
              })}
            </Box>
            <Box>
              <Typography variant="h5" sx={styles.title(card)}>
                {typeof card.title === 'number'
                  ? card.title.toLocaleString()
                  : card.title}
              </Typography>
              <Typography sx={styles.subtitle()}>{card.subtitle}</Typography>
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default DashboardCards;
