import { SxProps, Theme } from '@mui/system';

export const usePaymentFormStyles = (): Record<string, SxProps<Theme>> => ({
  cardInput: {
    backgroundColor: '#FFFFFF',
    border: '1.5px solid #EFF3FA',
    borderRadius: '8px',
    padding: '10px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '48px',
    marginBottom: '20px',
    '& .StripeElement': {
      width: '100%',
      padding: '12px 0',
    },
    '&:focus-within': {
      borderColor: '#24407E',
    },
  },
  textField: {
    width: '100%',
    marginBottom: '20px',
    '& .MuiOutlinedInput-root': {
      '& input': {
        textAlign: 'left',
      },
      '& fieldset': {
        borderColor: '#EFF3FA',
      },
      '&:hover fieldset': {
        borderColor: '#24407E',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#24407E',
      },
    },
  },
  formControlLabel: {
    '& .MuiTypography-root': {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '16px',
      color: '#030404',
    },
    '& .MuiRadio-root': {
      color: '#6C7378',
      '&.Mui-checked': {
        color: '#24407E',
      },
    },
    '&:hover': {
      backgroundColor: '#EFF3FA',
    },
    backgroundColor: '#FFFFFF',
    border: '1.5px solid #EFF3FA',
    borderRadius: '8px',
    padding: '12px 16px',
    justifyContent: 'space-between',
    marginBottom: '8px',
  },
  checkboxFormControlLabel: {
    '& .MuiTypography-root': {
      fontWeight: 700,
      fontSize: '12px',
      color: '#030404',
    },
    '& .MuiCheckbox-root': {
      color: '#24407E',
      '&.Mui-checked': {
        color: '#24407E',
      },
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    display: 'flex',
    alignItems: 'center',
    marginTop: '8px',
  },
  formControl: {
    width: '100%',
    margin: '0',
  },
  cardDetailsContainer: {
    display: 'flex',
    gap: '12px',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bgcolor: 'rgba(255,255,255,0.8)',
  },
  addCardButton: {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '18px',
    color: '#24407E',
    textTransform: 'none',
    marginTop: '8px',
    padding: '12px 16px',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  button: {
    width: '100%',
    height: 44,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px 20px',
    marginTop: '10px',
    background: '#24407E',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: '#1C3364',
    },
    color: '#FFFFFF',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
    textTransform: 'none',
    boxShadow: 'none',
    '& .MuiButton-startIcon': {
      display: 'none',
    },
    '& .MuiButton-endIcon': {
      display: 'none',
    },
  },
  typography: {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '28px',
    color: '#030404',
    marginBottom: '12px',
  },
  radioGroup: {
    flexDirection: 'column',
    gap: '8px',
    marginBottom: '12px',
  },
  useExistingCardButton: {
    fontWeight: 700,
    fontSize: '12px',
    color: '#24407E',
    textTransform: 'none',
    padding: '10px 16px',
    backgroundColor: 'transparent',
    border: '1.5px solid #EFF3FA',
    borderRadius: '8px',
    justifyContent: 'flex-start',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
    marginTop: '12px',
    gap: '8px',
    width: '100%',
  },
});
