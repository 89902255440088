import React, { useState } from 'react';
import { Box, Button, Popover, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FilterList } from '@mui/icons-material';

interface DateFilterProps {
  selectedDate: Date | null;
  onDateChange: (date: Date | null) => void;
  styles: Record<string, any>;
}

const DateFilter: React.FC<DateFilterProps> = ({
  selectedDate,
  onDateChange,
  styles,
}) => {
  const [dateFilterModalOpen, setDateFilterModalOpen] = useState(false);
  const [dateFilterAnchorEl, setDateFilterAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [tempSelectedDate, setTempSelectedDate] = useState<Date | null>(
    selectedDate
  );

  const handleOpenDateFilterModal = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setDateFilterAnchorEl(event.currentTarget);
    setDateFilterModalOpen(true);
  };

  const handleCloseDateFilterModal = () => {
    setDateFilterModalOpen(false);
    setDateFilterAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<FilterList />}
        onClick={handleOpenDateFilterModal}
        sx={{
          ...styles.filterButton,
          ...(selectedDate && {
            color: '#FFFFFF',
            backgroundColor: '#24407E',
            '&:hover': {
              backgroundColor: '#1e396f',
            },
          }),
        }}
      >
        {`Created Date`}
      </Button>
      <Popover
        open={dateFilterModalOpen}
        anchorEl={dateFilterAnchorEl}
        onClose={handleCloseDateFilterModal}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: styles.popover,
        }}
      >
        <Box sx={styles.filterTopSection}>
          <Typography variant="h6" sx={styles.filterTitle}>
            Filter
          </Typography>
          <Button
            onClick={() => {
              setTempSelectedDate(null);
            }}
            sx={styles.resetDateButton}
          >
            Clear Date
          </Button>
        </Box>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Select Date"
            value={tempSelectedDate}
            onChange={(date: Date | null) => setTempSelectedDate(date)}
            slotProps={{
              textField: { sx: { width: '100%', marginBottom: '16px' } },
            }}
          />
        </LocalizationProvider>

        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            onDateChange(tempSelectedDate);
            handleCloseDateFilterModal();
          }}
          sx={styles.applyDateButton}
        >
          Apply Filter
        </Button>
      </Popover>
    </>
  );
};

export default DateFilter;
