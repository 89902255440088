import * as Yup from 'yup';

export const registrationValidationSchema = Yup.object().shape({
  tradingName: Yup.string().required('Trading name is required'),
  legalName: Yup.string().required('Legal name is required'),
  address: Yup.string().required('Address is required'),
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Password must contain 8 characters, one uppercase, one lowercase, one number and one special character'
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Confirm password is required'),
  city: Yup.string().required('City is required'),
  province: Yup.string(),
  country: Yup.string().required('Country is required'),
  website: Yup.string(),
  contactPhone: Yup.string().required('Contact phone number is required'),
  contactEmail: Yup.string()
    .email('Invalid email')
    .required('Contact email is required'),
  legalRepresentative: Yup.string(),
  legalRepresentativeEmail: Yup.string().email('Invalid email'),
});
