import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Link,
  IconButton,
  Typography,
  InputAdornment,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { useChangePasswordModalStyles } from './styles';

interface ChangePasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
  onChangePassword: (currentPassword: string, newPassword: string) => void;
}

export const ChangePasswordModal = ({
  isOpen,
  onClose,
  onChangePassword,
}: ChangePasswordModalProps) => {
  const classes = useChangePasswordModalStyles();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [errors, setErrors] = useState({
    currentPassword: '',
    newPassword: '',
    repeatPassword: '',
  });

  const toggleShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleShowRepeatPassword = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  const handleSubmit = () => {
    if (validate()) {
      onChangePassword(currentPassword, newPassword);
      onClose();
    }
  };

  const validate = () => {
    let isValid = true;
    const newErrors = {
      currentPassword: '',
      newPassword: '',
      repeatPassword: '',
    };

    if (!currentPassword) {
      newErrors.currentPassword = 'Current password is required';
      isValid = false;
    }

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!newPassword) {
      newErrors.newPassword = 'New password is required';
      isValid = false;
    } else if (!passwordRegex.test(newPassword)) {
      newErrors.newPassword = 'Password does not meet minimum criteria.';
      isValid = false;
    }

    if (!repeatPassword) {
      newErrors.repeatPassword = 'Repeat password is required';
      isValid = false;
    } else if (newPassword !== repeatPassword) {
      newErrors.repeatPassword = "Passwords don't match";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      PaperProps={{
        sx: classes.paperProps,
      }}
    >
      <DialogTitle sx={classes.dialogTitle}>
        <Typography variant="h6" component="div" sx={classes.titleTypography}>
          Change Password
        </Typography>
        <IconButton onClick={onClose} sx={classes.closeIconButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={classes.dialogContent}>
        <TextField
          label="Current Password"
          type={showCurrentPassword ? 'text' : 'password'}
          fullWidth
          error={!!errors.currentPassword}
          helperText={errors.currentPassword}
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          margin="dense"
          sx={classes.textField}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={toggleShowCurrentPassword} edge="end">
                  {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="New Password"
          type={showNewPassword ? 'text' : 'password'}
          fullWidth
          value={newPassword}
          error={!!errors.newPassword}
          helperText={errors.newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          margin="dense"
          sx={classes.textField}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={toggleShowNewPassword} edge="end">
                  {showNewPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Repeat New Password"
          type={showRepeatPassword ? 'text' : 'password'}
          fullWidth
          value={repeatPassword}
          error={!!errors.repeatPassword}
          helperText={errors.repeatPassword}
          onChange={(e) => setRepeatPassword(e.target.value)}
          margin="dense"
          sx={classes.textField}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={toggleShowRepeatPassword} edge="end">
                  {showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions sx={classes.dialogActions}>
        <Button onClick={handleSubmit} fullWidth sx={classes.submitButton}>
          Change password
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordModal;
