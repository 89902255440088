import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
} from '@mui/material';
import { useSettings } from '../../../../contexts/SettingsContext';
import { fetchTransactions } from '../../../../api/settingsApi';
import { useBillingTabStyles } from './styles';
import { useQuery } from 'react-query';
import {
  fetchSavedPaymentMethods,
  removePaymentMethod,
  setDefaultPaymentMethod,
} from '../../../../api/paymentsApi';
import { toast } from 'react-toastify';
import { CardDetails } from '../../../elements/paymentForm/types';
import CandidateTrashIcon from '../../../elements/icons/CandidateTrashIcon';
import { CreditCard } from '@mui/icons-material';
import LoadingSpinner from '../../../elements/loading';

interface Transaction {
  id: number;
  amount: number;
  timestamp: string;
  status: string;
  tokens: number;
}

const BillingTab = () => {
  const { formData, handleFormDataChange } = useSettings();
  const billingInfo = formData.billing;
  const classes = useBillingTabStyles();
  const [savedPaymentMethods, setSavedPaymentMethods] = useState<CardDetails[]>(
    []
  );
  const [loadingPaymentMethods, setLoadingPaymentMethods] = useState(true);

  const fetchMethods = async () => {
    try {
      const methods: CardDetails[] = await fetchSavedPaymentMethods();
      setSavedPaymentMethods(methods);
    } catch (error) {
      console.error('Failed to fetch saved payment methods:', error);
      toast.error('Failed to fetch saved payment methods');
    } finally {
      setLoadingPaymentMethods(false);
    }
  };

  useEffect(() => {
    fetchMethods();
  }, []);

  const handleSetDefault = async (methodId: string) => {
    try {
      await setDefaultPaymentMethod(methodId);
      toast.success('Default payment method updated');
      await fetchMethods();
    } catch (error) {
      console.error('Failed to set default payment method:', error);
      toast.error('Failed to update default payment method');
    }
  };

  const handleRemovePaymentMethod = async (methodId: string) => {
    try {
      await removePaymentMethod(methodId);
      setSavedPaymentMethods((prev: any) =>
        prev.filter((method: any) => method.id !== methodId)
      );
      toast.success('Payment method removed successfully');
    } catch (error) {
      toast.error('Failed to remove payment method');
    }
  };

  const mergedPaymentButtonStyles = {
    ...classes.paymentMethodButton,
    ...classes.setDefaultButton,
  };

  const {
    data: transactions,
    isLoading,
    isError,
    error,
  } = useQuery<Transaction[]>('transactions', fetchTransactions);

  if (isLoading) return <LoadingSpinner />;
  if (isError)
    return (
      <Box>
        Error: {error instanceof Error ? error.message : 'Unknown error'}
      </Box>
    );

  return (
    <Box sx={classes.container}>
      <Typography variant="h6" gutterBottom sx={classes.headerTypography}>
        General info
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Company Name"
            fullWidth
            margin="normal"
            value={billingInfo.companyName || ''}
            onChange={(e) =>
              handleFormDataChange('billing', {
                ...billingInfo,
                companyName: e.target.value,
              })
            }
            sx={classes.textField}
          />
          <TextField
            label="VAT ID"
            fullWidth
            margin="normal"
            value={billingInfo.companyVatId || ''}
            onChange={(e) =>
              handleFormDataChange('billing', {
                ...billingInfo,
                companyVatId: e.target.value,
              })
            }
            sx={classes.textField}
          />
          <TextField
            label="Address"
            fullWidth
            margin="normal"
            value={billingInfo.companyAddress || ''}
            onChange={(e) =>
              handleFormDataChange('billing', {
                ...billingInfo,
                companyAddress: e.target.value,
              })
            }
            sx={classes.textField}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom sx={classes.headerTypography}>
            Saved Payment Methods
          </Typography>
          {loadingPaymentMethods ? (
            <LoadingSpinner small />
          ) : savedPaymentMethods.length > 0 ? (
            <List sx={classes.savedPaymentMethodsList}>
              {savedPaymentMethods.map((method) => (
                <ListItem key={method.id} sx={classes.savedPaymentMethodItem}>
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}
                  >
                    <ListItemText
                      primary={
                        <>
                          {`${method.brand.toUpperCase()} **** ${method.last4}`}
                          {method.isDefault && (
                            <Typography
                              variant="caption"
                              sx={{ ...classes.defaultFlag, ml: 1 }}
                            >
                              Default
                            </Typography>
                          )}
                        </>
                      }
                      secondary={`Exp: ${method.expMonth}/${method.expYear}`}
                    />
                  </Box>
                  <Box>
                    {!method.isDefault && (
                      <Button
                        variant="outlined"
                        onClick={() => handleSetDefault(method.id)}
                        sx={mergedPaymentButtonStyles}
                        startIcon={<CreditCard />}
                      >
                        Set as Default
                      </Button>
                    )}
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleRemovePaymentMethod(method.id)}
                      sx={classes.removeButton}
                      startIcon={<CandidateTrashIcon />}
                    >
                      Remove
                    </Button>
                  </Box>
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography variant="subtitle1">
              No saved payment methods.
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" sx={classes.transactionsHeader}>
            Transactions
          </Typography>
          {transactions!.length > 0 ? (
            <List>
              {transactions!.map((transaction) => {
                const formattedDate = new Intl.DateTimeFormat('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                }).format(new Date(transaction.timestamp));

                return (
                  <ListItem key={transaction.id} sx={classes.listItem}>
                    <ListItemText
                      primary={`+ ${transaction.amount} tokens`}
                      secondary={`${transaction.status} on ${formattedDate}`}
                      primaryTypographyProps={{ fontWeight: 500 }}
                      secondaryTypographyProps={{
                        fontWeight: 500,
                        style: {
                          color:
                            transaction.status === 'succeeded'
                              ? 'green'
                              : 'red',
                        },
                      }}
                    />
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              There are no transactions.
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default BillingTab;
