import { SxProps, Theme } from '@mui/system';

export const usePaymentModalStyles = (): Record<string, SxProps<Theme>> => ({
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleTypography: {
    fontWeight: 700,
    fontSize: '24px',
    color: '#24407E',
  },
  closeButton: {
    color: '#030404',
  },
  captionTypography: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#6C7378',
    mt: 2,
  },
  minimumPurchaseTypography: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#6C7378',
  },
  tokenAmountBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    mt: 1,
    mb: 4,
    border: '1px solid #24407E',
    borderRadius: '6px',
    width: '100%',
    height: '40px',
  },
  iconButton: {
    flex: 'none',
    color: '#24407E',
  },
  input: {
    flex: '1 1 auto',
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: '700',
  },
});
