import { SxProps } from '@mui/system';

export const useAddEditCandidateStyles = (): Record<string, SxProps> => ({
  navigationBox: {
    display: 'flex',
    alignItems: 'center',
    mt: 4,
  },
  navigationTypo: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#030404',
    mr: 0.75,
  },
  navigationSlash: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#6C7378',
  },
  navigationName: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#6C7378',
    ml: 0.75,
  },
  mainTitle: {
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 2,
    gap: { xs: 2, sm: 0 },
  },
  candidateTitle: {
    fontWeight: 700,
    fontSize: '32px',
    lineHeight: '48px',
    color: '#24407E',
    flexGrow: 1,
    mb: { xs: 2, sm: 0 },
  },
  editButtonBox: {
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },
    alignItems: 'center',
    gap: { xs: 1, sm: 1.5 },
    flexWrap: 'wrap',
  },
  editModeButtonBox: {
    display: 'flex',
    flexDirection: { xs: 'column-reverse', sm: 'row' },
    justifyContent: 'flex-end',
    gap: 2,
    marginTop: { xs: 2, sm: 0 },
  },
  buttonOutlined: {
    minWidth: '40px',
    height: '40px',
    border: '1px solid',
    borderRadius: '6px',
    '& .MuiSvgIcon-root': {
      color: 'inherit',
    },
    width: { xs: '100%', sm: 'auto' },
  },
  sendTestsButton: {
    minWidth: '124px',
    height: '40px',
    backgroundColor: '#24407E',
    borderRadius: '6px',
    color: '#FFFFFF',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 700,
    width: { xs: '100%', sm: 'auto' },
  },
  cancelResetButton: {
    minWidth: '81px',
    height: '36px',
    color: '#24407E',
    textTransform: 'none',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '16px',
    width: { xs: '100%', sm: 'auto' },
  },
  createButton: {
    minWidth: '79px',
    height: '36px',
    backgroundColor: '#24407E',
    borderRadius: '6px',
    color: '#FFFFFF',
    textTransform: 'none',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '16px',
    width: { xs: '100%', sm: 'auto' },
  },
  generalInfoPaper: {
    p: 4,
    mb: 2,
  },
  generalInfoTitle: {
    fontSize: '20px',
    lineHeight: '36px',
    fontWeight: 700,
  },
});
