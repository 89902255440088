import { SxProps, Theme } from '@mui/system';

export const useImportResultsTableStyles = (): Record<
  string,
  SxProps<Theme>
> => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'auto',
  },
  modalBox: {
    p: 4,
    backgroundColor: 'white',
    margin: 'auto',
    minWidth: 600,
    borderRadius: '8px',
    boxShadow: 24,
    maxHeight: '90vh',
    overflowY: 'auto',
    '@media (max-width: 600px)': {
      minWidth: '90%',
    },
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    marginRight: '24px',
    fontWeight: 700,
    fontSize: '32px',
    lineHeight: '48px',
    color: '#24407E',
  },
  downloadButton: {
    height: '40px',
    backgroundColor: '#24407E',
    borderRadius: '6px',
    padding: '10px 16px',
    '&:hover': {
      backgroundColor: '#1e396f',
    },
  },
  showResultsButton: {
    border: '1px solid #24407E',
    padding: '6px 12px',
    color: '#24407E',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  },
  boldText: {
    fontWeight: 'bold',
  },
  closeIconButton: {
    position: 'absolute',
    right: '15px',
    top: '20px',
    color: '#24407E',
  },
  errorList: {
    maxHeight: '200px',
    overflowY: 'auto',
    paddingLeft: '20px',
  },
  closeButton: {
    marginTop: 2,
    background: '#24407E',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#1e396f',
    },
  },
});
