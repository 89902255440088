import React, { ReactNode } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import StripeProvider from './StripeProvider';
import LoadingSpinner from '../components/elements/loading';

interface ProtectedRouteProps {
  children?: ReactNode;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { user, token, isLoading } = useAuth();

  if (isLoading) return <LoadingSpinner />;

  return user && token ? (
    <StripeProvider>{children || <Outlet />}</StripeProvider>
  ) : (
    <Navigate to="/login" replace />
  );
};

export default ProtectedRoute;
