import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

export const useCandidatesTableStyles = (): Record<string, SxProps<Theme>> => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  topRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px',
    gap: '24px',
    '@media (max-width:600px)': {
      flexDirection: 'column',
      gap: '16px',
    },
  },
  titleButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '10px',
    width: 'auto',
    '@media (max-width:600px)': {
      justifyContent: 'center',
      width: '100%',
    },
  },
  title: {
    marginRight: '24px',
    fontWeight: 700,
    fontSize: '32px',
    lineHeight: '48px',
    color: '#24407E',
  },
  addButton: {
    height: '40px',
    backgroundColor: '#24407E',
    borderRadius: '6px',
    padding: '10px 16px',
    '&:hover': {
      backgroundColor: '#1e396f',
    },
  },
  importButton: {
    height: '40px',
    border: '1px solid #24407E',
    borderRadius: '6px',
    padding: '10px 16px',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    color: '#24407E',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  },
  sendTestButton: {
    height: '40px',
    backgroundColor: '#24407E',
    borderRadius: '6px',
    padding: '10px 16px',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#1e396f',
    },
  },
  searchFilterContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '12px',
    '@media (max-width:600px)': {
      justifyContent: 'center',
      width: '100%',
      flexWrap: 'wrap',
    },
  },
  filterButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 16px',
    width: '160px',
    height: '40px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#030404',
    background: '#FFFFFF',
    border: '1px solid #24407E',
    borderRadius: '8px',
    '&:hover': {
      border: '1px solid #24407E',
      boxShadow: 'none',
    },
  },
  completedOnFilterButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 16px',
    width: '180px',
    height: '40px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#030404',
    background: '#FFFFFF',
    border: '1px solid #24407E',
    borderRadius: '8px',
    '&:hover': {
      border: '1px solid #24407E',
      boxShadow: 'none',
    },
  },
  searchTextField: {
    width: '240px',
    height: '40px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      background: '#FFFFFF',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      color: '#6C7378',
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#24407E',
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '10px',
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: '0px',
    },
  },
  searchIcon: {
    color: '#6C7378',
    marginRight: '10px',
    marginLeft: '10px',
  },
  popover: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    gap: '16px',
    minWidth: '252px',
    height: 'auto',
    background: '#FFFFFF',
    border: '1px solid #E7ECEE',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    overflow: 'visible',
    '& .MuiFormControlLabel-root': {
      marginRight: 0,
    },
  },
  resetButton: {
    width: '120px',
    height: '32px',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#24407E',
    textTransform: 'none',
  },
  resetDateButton: {
    width: '120px',
    height: '32px',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#24407E',
    justifyContent: 'end',
    textTransform: 'none',
  },
  applyButton: {
    width: '252px',
    height: '32px',
    background: '#24407E',
    borderRadius: '4px',
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '14px',
    color: '#FFFFFF',
    marginTop: 'auto',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1e396f',
    },
  },
  applyDateButton: {
    width: '260px',
    height: '32px',
    background: '#24407E',
    borderRadius: '4px',
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '14px',
    color: '#FFFFFF',
    marginTop: 'auto',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1e396f',
    },
  },
  filterTopSection: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filterTitle: {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '28px',
  },
  statusCell: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    gap: 1,
    alignItems: 'center',
  },
  editCellIcon: {
    color: '#24407E',
    '&:hover': {
      color: '#24407E',
      opacity: 0.8,
    },
  },
  removeCellIcon: {
    color: '#A73737',
    '&:hover': { color: '#A73737', opacity: 0.8 },
  },
  dataGrid: {
    '& .MuiDataGrid-root': {
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      marginBottom: '20px',
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: '#f5f5f5',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0px 16px',
      height: '56px',
      borderBottom: '1px solid #E7ECEE',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      color: '#6C7378',
      textAlign: 'left',
    },
    '& .MuiDataGrid-cell': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '8px 16px',
      gap: '8px',
      height: '64px',
      border: 'none',
      '&:last-of-type': {
        paddingRight: '16px',
      },
    },
    '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
      borderBottom: '1px solid #E7ECEE',
    },
    '& .MuiDataGrid-sortIcon, & .MuiDataGrid-filterIcon': {
      color: '#6C7378',
    },
  },
});
