const SquareStackedIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.42857 8.75L1.25 11L5.42857 13.25M5.42857 8.75L11 11.75L16.5714 8.75M5.42857 8.75L1.25 6.5L11 1.25L20.75 6.5L16.5714 8.75M16.5714 8.75L20.75 11L16.5714 13.25M16.5714 13.25L20.75 15.5L11 20.75L1.25 15.5L5.42857 13.25M16.5714 13.25L11 16.25L5.42857 13.25"
      stroke="#24407E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SquareStackedIcon;
