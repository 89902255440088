import { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { ChangePasswordModal } from '../../../elements/modals/ChangePasswordModal';
import { useSettings } from '../../../../contexts/SettingsContext';
import { getData } from 'country-list';
import { uploadLogo } from '../../../../api/settingsApi';
import { useGeneralTabStyles } from './styles';
import FileUploadBox from '../../../elements/fileUploadBox';
import { useUserContext } from '../../../../contexts/UserContext';

const countryOptions = getData().sort((a, b) => a.name.localeCompare(b.name));

interface GeneralTabProps {
  handleChangePassword: (
    currentPassword: string,
    newPassword: string
  ) => Promise<void> | void;
  formErrors: SettingsFormErrors;
}

const GeneralTab = ({ handleChangePassword, formErrors }: GeneralTabProps) => {
  const { formData, handleFormDataChange } = useSettings();
  const { user } = useUserContext();
  const classes = useGeneralTabStyles();
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);

  const handleFieldChange = (fieldName: string, value: any) => {
    handleFormDataChange('general', {
      ...formData.general,
      [fieldName]: value,
    });
  };

  const onFileRemove = () => {
    handleFieldChange('logoResourceId', null);
  };

  const onFileChange = async (selectedFile: File | null) => {
    if (selectedFile) {
      try {
        const resourceId = await uploadLogo(selectedFile);
        handleFieldChange('logoResourceId', resourceId);
      } catch (error) {
        console.error('Error uploading logo:', error);
      }
    }
  };

  return (
    <Box sx={classes.mainContainer}>
      <Box sx={classes.headerContainer}>
        <Box sx={classes.textContainer}>
          <Typography variant="body1" sx={classes.mainTypography}>
            Password
          </Typography>
          <Typography variant="body2" sx={classes.subTypography}>
            Manage your password settings
          </Typography>
        </Box>
        <Button
          sx={classes.changePasswordButton}
          onClick={() => setIsChangePasswordModalOpen(true)}
        >
          Change password
        </Button>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FileUploadBox
            file={formData.general.logo ?? null}
            logoUrl={user?.logoUrl ?? null}
            onFileChange={onFileChange}
            onFileRemove={onFileRemove}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Trading Name"
            fullWidth
            variant="outlined"
            required
            margin="normal"
            error={!!formErrors.tradingName}
            helperText={formErrors.tradingName}
            value={formData.general.tradingName}
            onChange={(e) => handleFieldChange('tradingName', e.target.value)}
            sx={classes.textField}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Legal Name"
            required
            fullWidth
            variant="outlined"
            margin="normal"
            value={formData.general.legalName}
            error={!!formErrors.legalName}
            helperText={formErrors.legalName}
            onChange={(e) => handleFieldChange('legalName', e.target.value)}
            sx={classes.textField}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Address"
            required
            fullWidth
            variant="outlined"
            margin="normal"
            value={formData.general.address}
            error={!!formErrors.address}
            onChange={(e) => handleFieldChange('address', e.target.value)}
            sx={classes.textField}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="City"
            required
            fullWidth
            variant="outlined"
            margin="normal"
            value={formData.general.city}
            error={!!formErrors.city}
            helperText={formErrors.city}
            onChange={(e) => handleFieldChange('city', e.target.value)}
            sx={classes.textField}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Province"
            fullWidth
            variant="outlined"
            margin="normal"
            value={formData.general.province}
            onChange={(e) => handleFieldChange('province', e.target.value)}
            sx={classes.textField}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Country"
            fullWidth
            required
            variant="outlined"
            margin="normal"
            select
            value={formData.general.country}
            error={!!formErrors.country}
            helperText={formErrors.country}
            onChange={(e) => handleFieldChange('country', e.target.value)}
            sx={classes.textField}
          >
            {countryOptions.map((option) => (
              <MenuItem key={option.code} value={option.code}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Website"
            fullWidth
            variant="outlined"
            margin="normal"
            value={formData.general.website}
            onChange={(e) => handleFieldChange('website', e.target.value)}
            sx={classes.textField}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Contact Phone Number"
            required
            fullWidth
            variant="outlined"
            margin="normal"
            value={formData.general.contactPhone}
            error={!!formErrors.contactPhone}
            helperText={formErrors.contactPhone}
            onChange={(e) => handleFieldChange('contactPhone', e.target.value)}
            sx={classes.textField}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Contact E-mail Address"
            fullWidth
            variant="outlined"
            margin="normal"
            required
            disabled
            value={formData.general.contactEmail}
            error={!!formErrors.contactEmail}
            helperText={formErrors.contactEmail}
            onChange={(e) => handleFieldChange('contactEmail', e.target.value)}
            sx={classes.textField}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Legal Representative"
            fullWidth
            variant="outlined"
            margin="normal"
            value={formData.general.legalRepresentative}
            onChange={(e) =>
              handleFieldChange('legalRepresentative', e.target.value)
            }
            sx={classes.textField}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Legal Representative E-mail"
            fullWidth
            variant="outlined"
            margin="normal"
            value={formData.general.legalRepresentativeEmail}
            onChange={(e) =>
              handleFieldChange('legalRepresentativeEmail', e.target.value)
            }
            sx={classes.textField}
          />
        </Grid>
      </Grid>

      <ChangePasswordModal
        isOpen={isChangePasswordModalOpen}
        onClose={() => setIsChangePasswordModalOpen(false)}
        onChangePassword={handleChangePassword}
      />
    </Box>
  );
};

export default GeneralTab;
