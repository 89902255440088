import { SxProps } from '@mui/system';

export const useDashboardStyles = (): Record<string, SxProps> => ({
  container: {
    pt: 4,
    px: 3,
  },
  title: {
    fontWeight: 700,
    fontSize: '32px',
    lineHeight: '48px',
    color: '#24407E',
    mb: 5,
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    isolation: 'isolate',
    background: '#FFFFFF',
    border: '1px solid #EFF3FA',
    width: '720px',
    borderRadius: '12px',
    height: '326px',
    position: 'relative',
    boxSizing: 'border-box',
  },
  gridItemTitleBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',
    gap: '24px',
    height: '52px',
    position: 'absolute',
    top: '20px',
    left: '20px',
  },
  iconBox: {
    padding: '12px',
    background: '#EFF3FA',
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconStyle: {
    color: '#24407E',
    width: '24px',
    height: '24px',
  },
  countTypography: {
    fontWeight: 700,
    fontSize: '24px',
    color: '#24407E',
  },
  labelTypography: {
    fontWeight: 500,
    fontSize: '12px',
    color: '#6480BC',
  },
  legendBox: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '8px',
    position: 'absolute',
    bottom: '20px',
    left: '20px',
  },
  legendItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
  },
  legendColorBox: {
    width: '26.17px',
    height: '9.97px',
    borderRadius: '2px',
  },
  legendNameTypography: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#030404',
  },
  legendValueTypography: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#6C7378',
  },
  secondaryGridItem: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    height: '326px',
    position: 'relative',
    width: '720px',
    background: '#FFFFFF',
    border: '1px solid #EFF3FA',
    borderRadius: '12px',
    boxSizing: 'border-box',
  },
  noDataMessage: {
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
  },
});
