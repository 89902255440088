import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

interface PublicRouteProps {
  children: ReactNode;
}

const PublicRoute = ({ children }: PublicRouteProps) => {
  const { token } = useAuth();
  return token ? <Navigate to="/" replace /> : <>{children}</>;
};

export default PublicRoute;
