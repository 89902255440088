import React from 'react';
import { Grid, TextField, Box } from '@mui/material';
import { useSettings } from '../../../../contexts/SettingsContext';
import { useEmailTemplatesTabStyles } from './styles';

const EmailTemplatesTab = () => {
  const { formData, handleFormDataChange } = useSettings();
  const emailTemplates = formData.emailTemplates;
  const classes = useEmailTemplatesTabStyles();

  return (
    <Box sx={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Send Test Template"
            multiline
            rows={4}
            fullWidth
            margin="normal"
            value={emailTemplates.sendTestTemplate || ''}
            onChange={(e) =>
              handleFormDataChange('emailTemplates', {
                ...emailTemplates,
                sendTestTemplate: e.target.value,
              })
            }
            sx={classes.textField}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Reminder Template"
            multiline
            rows={4}
            fullWidth
            margin="normal"
            value={emailTemplates.reminderTemplate || ''}
            onChange={(e) =>
              handleFormDataChange('emailTemplates', {
                ...emailTemplates,
                reminderTemplate: e.target.value,
              })
            }
            sx={classes.textField}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmailTemplatesTab;
