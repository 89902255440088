import { SxProps, Theme } from '@mui/system';

export const useLoginStyles = (): Record<string, SxProps<Theme>> => ({
  mainContainer: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    minHeight: '100vh',
    backgroundColor: '#EFF3FA',
    maxWidth: 'none',
  },
  logoSection: {
    width: { md: '30%' },
    flexShrink: 0,
    backgroundColor: '#0E182F',
    padding: { xs: '20px', md: '40px 56px' },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  topLogo: {
    width: '120px',
    height: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bottomLogo: {
    width: '89px',
    height: '74px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginFormSection: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: { xs: '20px', md: '0' },
  },
  formStyle: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    width: { xs: '100%', sm: '75%', md: '624px' },
    mx: 'auto',
  },
  heading: {
    fontWeight: 700,
    fontSize: { xs: '24px', sm: '32px' },
    color: '#24407E',
    marginBottom: '24px',
  },
  inputStyle: {
    '& .MuiInputBase-input': {
      fontSize: '12px',
      lineHeight: '16px',
      color: '#6C7378',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#EFF3FA',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      backgroundColor: '#EFF3FA',
      margin: 0,
      color: '#f44336',
    },
    bgcolor: '#FFFFFF',
    borderRadius: '8px',
    border: '1.5px solid #EFF3FA',
    width: '100%',
  },
  loginButton: {
    bgcolor: '#24407E',
    color: '#FFFFFF',
    textTransform: 'none',
    borderRadius: '8px',
    '&:hover': { bgcolor: '#1c2532' },
    padding: '12px 20px',
    marginY: '24px',
  },
  forgotPasswordLink: {
    display: 'block',
    textAlign: 'center',
    textDecoration: 'underline',
    color: '#24407E',
    fontWeight: 700,
    marginBottom: '12px',
  },
  accessText: {
    textAlign: 'center',
    color: '#24407E',
    fontWeight: 700,
    marginBottom: '12px',
    lineHeight: '20px',
  },
  registerButton: {
    bgcolor: '#FFFFFF',
    color: '#24407E',
    textTransform: 'none',
    border: '1px solid #24407E',
    borderRadius: '8px',
    '&:hover': { bgcolor: '#f0f0f0' },
    padding: '12px 20px',
  },
});
