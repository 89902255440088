import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './contexts/AuthContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { UserProvider } from './contexts/UserContext';
import { GlobalStyles, ThemeProvider, createTheme } from '@mui/material';
import './fonts/poppinsFont.css';

const queryClient = new QueryClient();

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, Arial, sans-serif',
  },
});

const globalStyles = (
  <GlobalStyles
    styles={{
      '.react-datepicker-wrapper, .react-datepicker__input-container': {
        width: '100%',
      },
      '.react-datepicker__input-container input': {
        width: '100%',
      },
    }}
  />
);

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      {globalStyles}
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <UserProvider>
            <App />
            <ToastContainer autoClose={1000} hideProgressBar={true} />
          </UserProvider>
        </AuthProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>
);
