import { useQuery } from 'react-query';
import { fetchLoggedInUserInfo } from '../api/userApi';
import { useAuth } from '../contexts/AuthContext';

export const useUser = () => {
  const { token, logout } = useAuth();

  return useQuery('userInfo', fetchLoggedInUserInfo, {
    enabled: !!token,
    onError: (error: any) => {
      if (error.response && error.response.status === 401) {
        logout();
      }
    },
  });
};
