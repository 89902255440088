export const formatDate = (date: string | number | Date): string => {
  if (!date) return 'N/A';
  return new Date(date).toLocaleDateString();
};

export const getStatusChipProps = (status: string) => {
  switch (status) {
    case 'Expired':
      return {
        label: 'Expired',
        backgroundColor: '#F3D8DC',
        color: '#C43B52',
        dotColor: '#C43B52',
      };
    case 'Pending':
      return {
        label: 'Pending',
        backgroundColor: '#FEEFD7',
        color: '#E79008',
        dotColor: '#E79008',
      };
    case 'None':
      return {
        label: 'In progress',
        backgroundColor: '#D3E9E6',
        color: '#007D6C',
        dotColor: '#007D6C',
      };
    case 'Completed':
      return {
        label: 'Completed',
        backgroundColor: '#C8E6C9',
        color: '#3C763D',
        dotColor: '#3C763D',
      };
    default:
      return {
        label: status,
        backgroundColor: 'transparent',
        color: '#000000',
        dotColor: 'transparent',
      };
  }
};
