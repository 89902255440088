import { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Modal,
  Paper,
  IconButton,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from 'react-query';
import { fetchImportResults } from '../../../../api/candidatesApi';
import { format, parseISO } from 'date-fns';
import LoadingSpinner from '../../loading';
import CloseIcon from '@mui/icons-material/Close';
import { useImportResultsTableStyles } from './styles';
import { DownloadSharp } from '@mui/icons-material';

const ImportResultsTable = ({ handleDownloadTemplate }: any) => {
  const classes = useImportResultsTableStyles();
  const {
    data: importResults,
    isLoading,
    isError,
  } = useQuery('importResults', fetchImportResults);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [detailErrors, setDetailErrors] = useState<string[]>([]);
  const [selectedImport, setSelectedImport] = useState<any>(null);

  const columns = [
    { field: 'fileName', headerName: 'File Name', width: 300 },
    {
      field: 'timestamp',
      headerName: 'Date of Import',
      width: 300,
      valueFormatter: (params: any) => {
        const date = parseISO(params.value);
        return isNaN(date.getTime()) ? '' : format(date, 'dd.MM.yyyy HH:mm');
      },
    },
    { field: 'successfulCount', headerName: 'Successful Imports', width: 300 },
    { field: 'errorCount', headerName: 'Errors', width: 300 },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      flex: 1,
      getActions: (params: any) => [
        <Button
          onClick={() => {
            setDetailErrors(
              params.row.errors ? params.row.errors.split(';') : []
            );
            setSelectedImport(params.row);
            setIsDetailModalOpen(true);
          }}
          sx={classes.showResultsButton}
        >
          Show Results
        </Button>,
      ],
    },
  ];

  if (isLoading) return <LoadingSpinner />;
  if (isError) return <div>An error occurred</div>;

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Typography variant="h6" sx={classes.title}>
          Import Results
        </Typography>
        <Button
          variant="contained"
          onClick={handleDownloadTemplate}
          sx={classes.downloadButton}
          endIcon={<DownloadSharp />}
        >
          Download Import Template
        </Button>
      </Box>
      <Paper>
        <DataGrid rows={importResults ?? []} columns={columns} autoHeight />
      </Paper>

      <Modal
        open={isDetailModalOpen}
        onClose={() => setIsDetailModalOpen(false)}
        sx={classes.modal}
      >
        <Box sx={classes.modalBox}>
          <Box sx={classes.modalHeader}>
            <Typography variant="h6">Import Details</Typography>
            <IconButton
              onClick={() => setIsDetailModalOpen(false)}
              sx={classes.closeIconButton}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box mt={2}>
            <Typography variant="body1" sx={classes.boldText}>
              File Name:
            </Typography>
            <Typography variant="body1">{selectedImport?.fileName}</Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body1" sx={classes.boldText}>
              Date of Import:
            </Typography>
            <Typography variant="body1">
              {selectedImport?.timestamp
                ? format(parseISO(selectedImport.timestamp), 'dd.MM.yyyy HH:mm')
                : 'N/A'}
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body1" sx={classes.boldText}>
              Successful Imports:
            </Typography>
            <Typography variant="body1">
              {selectedImport?.successfulCount}
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body1" sx={classes.boldText}>
              Errors:
            </Typography>
            <Typography variant="body1">
              {selectedImport?.errorCount}
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body2" sx={classes.boldText}>
              Error Details:
            </Typography>
            {detailErrors.length > 0 ? (
              <Box component="ul" sx={classes.errorList}>
                {detailErrors.map((error, index) => (
                  <Box component="li" key={index}>
                    {error}
                  </Box>
                ))}
              </Box>
            ) : (
              <Typography variant="body2">No errors</Typography>
            )}
          </Box>
          <Button
            onClick={() => setIsDetailModalOpen(false)}
            sx={classes.closeButton}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default ImportResultsTable;
