import { User } from '../types/user';
import baseApi from './baseApi';
import { AxiosError } from 'axios';

interface LoginData {
  email: string;
  password: string;
}

interface LoginResponse {
  token: string;
  user: User;
}

export const loginUser = async (data: LoginData): Promise<LoginResponse> => {
  try {
    const response = await baseApi.post<LoginResponse>(
      '/business/authentication/SignIn',
      data
    );
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw new Error(axiosError.message);
  }
};
