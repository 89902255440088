import { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  DialogActions,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SendTestModalProps } from '../../../../types/placementTest';
import { useSendTestModalStyles } from './styles';

const languages = [
  { name: 'English', id: 1 },
  { name: 'Italian', id: 2 },
  { name: 'Spanish', id: 3 },
  { name: 'German', id: 4 },
  { name: 'French', id: 5 },
  { name: 'Russian', id: 6 },
];

export const SendTestModal = ({
  isOpen,
  onClose,
  onSendTest,
  pendingLanguageIds = [],
  numberOfCandidates,
}: SendTestModalProps & { numberOfCandidates: number }) => {
  const [selectedLanguageId, setSelectedLanguageId] = useState<number | null>(
    null
  );
  const classes = useSendTestModalStyles();

  const handleSubmit = () => {
    if (selectedLanguageId !== null) {
      onSendTest(selectedLanguageId);
      setSelectedLanguageId(null);
      onClose();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{ sx: classes.dialogPaper }}
    >
      <DialogTitle sx={classes.dialogTitle}>
        Send test
        <IconButton edge="end" color="inherit" onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={classes.dialogContent}>
        <RadioGroup
          value={selectedLanguageId}
          onChange={(event) =>
            setSelectedLanguageId(Number(event.target.value))
          }
        >
          {languages.map((language) => (
            <FormControlLabel
              key={language.id}
              value={language.id.toString()}
              control={<Radio />}
              label={language.name}
              disabled={pendingLanguageIds.includes(language.id)}
              sx={classes.formControlLabel}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions sx={classes.dialogActions}>
        <Button
          onClick={handleSubmit}
          fullWidth
          sx={classes.button}
          disabled={selectedLanguageId === null}
        >
          {`Send Test${numberOfCandidates > 1 ? 's' : ''} (${numberOfCandidates} token${numberOfCandidates > 1 ? 's' : ''})`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendTestModal;
