import React from 'react';
import { Chip, Box } from '@mui/material';
import { styled } from '@mui/system';
import { StatusChipProps } from './types';

const Dot = styled('div')(({ theme, color }) => ({
  width: '6px',
  height: '6px',
  borderRadius: '50%',
  backgroundColor: color,
}));

const StatusChip = ({
  label,
  backgroundColor,
  color,
  dotColor,
}: StatusChipProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '4px 8px',
        gap: '8px',
        borderRadius: '6px',
        backgroundColor,
      }}
    >
      <Dot color={dotColor} />
      <Chip
        label={label}
        size="small"
        sx={{
          color,
          fontWeight: 700,
          fontSize: '12px',
          backgroundColor: 'transparent',
          borderRadius: '2px',
        }}
      />
    </Box>
  );
};

export default StatusChip;
