import { Box } from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import NotificationsIcon from '@mui/icons-material/Notifications';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useState } from 'react';
import { CandidateTestResultsModal } from '../../elements/modals/CandidateTestResultsModal';
import { DeleteModal } from '../../elements/modals/DeleteModal';
import { useCandidateTestsStyles } from './styles';
import { formatDate, getStatusChipProps } from './utils';
import { CandidateTestsProps } from './types';
import { StatusChip } from '../../elements/statusChips';
import CustomNoRowsOverlay from '../../elements/table/CustomNoRowsOverlay';
import { Test } from '../../../types/candidates';

interface Language {
  name: string;
  id: number;
}

const languages: Language[] = [
  { name: 'English', id: 1 },
  { name: 'Italian', id: 2 },
  { name: 'Spanish', id: 3 },
  { name: 'German', id: 4 },
  { name: 'French', id: 5 },
  { name: 'Russian', id: 6 },
];

type LanguageLookup = { [id: number]: string };

const languageLookup: LanguageLookup = languages.reduce(
  (acc: LanguageLookup, current: Language) => {
    acc[current.id] = current.name;
    return acc;
  },
  {}
);

const CandidateTests = ({
  candidateName,
  tests,
  onSendReminder,
  onCancelAndDelete,
}: CandidateTestsProps) => {
  const [isTestResultsModalOpen, setIsTestResultsModalOpen] = useState(false);
  const [selectedTestId, setSelectedTestId] = useState<number | null>(null);
  const [selectedTestIdToDelete, setSelectedTestIdToDelete] = useState<
    number | null
  >(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { dataGridSx, rowSx, statusCell } = useCandidateTestsStyles();

  const handleCancelAndDelete = (testId: number) => {
    setSelectedTestIdToDelete(testId);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedTestIdToDelete(null);
  };

  const closeTestResultsModal = () => {
    setIsTestResultsModalOpen(false);
    setSelectedTestId(null);
  };

  const getActions = (params: any) => {
    let actions = [];

    if (params.row.status !== 'Completed' && params.row.status !== 'Expired') {
      actions.push(
        <GridActionsCellItem
          icon={<NotificationsIcon />}
          onClick={() => onSendReminder(params.row.invitationId)}
          label="Send Reminder"
          showInMenu
        />
      );
    }

    if (params.row.status === 'Pending' || params.row.status === 'Expired') {
      actions.push(
        <GridActionsCellItem
          icon={<DeleteIcon />}
          onClick={() => handleCancelAndDelete(params.row.invitationId)}
          label="Cancel and Delete"
          showInMenu
        />
      );
    }

    if (params.row.status === 'Completed') {
      actions.push(
        <GridActionsCellItem
          icon={<VisibilityIcon />}
          onClick={() => {
            setSelectedTestId(params.row.placementTestId);
            setIsTestResultsModalOpen(true);
          }}
          label="Show Test"
          showInMenu
        />
      );
    }

    return actions;
  };

  const columns = [
    {
      field: 'languageId',
      headerName: 'Language',
      width: 120,
      valueGetter: (params: GridValueGetterParams<Test, Test>) =>
        languageLookup[params.row.languageId] || 'Unknown',
    },
    {
      field: 'actualLevel',
      headerName: 'Level',
      width: 120,
      valueGetter: (params: any) =>
        params.row.completedOn ? params.row.actualLevel : 'N/A',
    },
    {
      field: 'score',
      headerName: 'Score',
      width: 120,
      valueGetter: (params: any) =>
        params.row.completedOn ? params.row.score : 'N/A',
    },
    {
      field: 'readingScore',
      headerName: 'Reading',
      width: 120,
      valueGetter: (params: any) =>
        params.row.completedOn ? params.row.readingScore : 'N/A',
    },
    {
      field: 'listeningScore',
      headerName: 'Listening',
      width: 120,
      valueGetter: (params: any) =>
        params.row.completedOn ? params.row.listeningScore : 'N/A',
    },
    {
      field: 'startedOn',
      headerName: 'Administered',
      width: 150,
      valueGetter: (params: any) => formatDate(params.row.startedOn),
    },
    {
      field: 'completedOn',
      headerName: 'Completed',
      width: 150,
      valueGetter: (params: any) => formatDate(params.row.completedOn),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 130,
      renderCell: (params: any) => (
        <Box sx={statusCell}>
          <StatusChip {...getStatusChipProps(params.value)} />
        </Box>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      flex: 1,
      getActions: getActions,
    },
  ];

  const rows = tests.map((test, index) => ({
    id: index,
    ...test,
  }));

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        autoHeight
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        sx={dataGridSx}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        slotProps={{
          row: {
            sx: rowSx,
          },
        }}
      />

      {selectedTestId !== null && (
        <CandidateTestResultsModal
          testId={selectedTestId}
          isOpen={isTestResultsModalOpen}
          onClose={closeTestResultsModal}
          candidateName={candidateName}
        />
      )}
      {selectedTestIdToDelete !== null && (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          onClose={closeDeleteModal}
          onDelete={() => {
            onCancelAndDelete(selectedTestIdToDelete);
            closeDeleteModal();
          }}
          title="Are you sure you want to cancel and delete the test?"
          description="This will delete the candidate and all the related data, including tests and test results.  This action cannot be undone."
        />
      )}
    </>
  );
};

export default CandidateTests;
