import { SxProps } from '@mui/system';

export const useGeneralTabStyles = (): Record<string, SxProps> => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    background: '#FFFFFF',
    borderRadius: '8px',
    width: '100%',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
    gap: '4px',
    height: '68px',
    background: '#FFFFFF',
    border: '1.5px solid #EFF3FA',
    borderRadius: '8px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px',
    height: '36px',
  },
  mainTypography: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#030404',
  },
  subTypography: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '16px',
    color: '#6C7378',
  },
  changePasswordButton: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '16px',
    textAlign: 'center',
    color: '#24407E',
    padding: '8px 16px',
    width: '170px',
    height: '36px',
    background: '#FFFFFF',
    borderRadius: '4px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#f4f6f8',
    },
  },
  textFieldRoot: {
    '.MuiInputLabel-root': {
      color: '#030404',
      fontSize: '16px',
      fontWeight: 500,
    },
    '.MuiOutlinedInput-root': {
      background: '#FFFFFF',
      border: '1.5px solid #EFF3FA',
      borderRadius: '8px',
      '.MuiOutlinedInput-input': {
        color: '#6C7378',
        fontSize: '16px',
        fontWeight: 500,
      },
    },
  },
});
