const CheckBadgeIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 10.75L9.25 13L13 7.75M19 10C19 11.2683 18.3704 12.3895 17.4067 13.0682C17.6081 14.2294 17.2604 15.4672 16.3637 16.3639C15.467 17.2606 14.2292 17.6083 13.068 17.4069C12.3893 18.3705 11.2682 19 10 19C8.73185 19 7.61072 18.3705 6.93204 17.407C5.77066 17.6086 4.53256 17.261 3.6357 16.3641C2.73886 15.4673 2.39125 14.2292 2.59286 13.0678C1.62941 12.3891 1 11.2681 1 10C1 8.73188 1.62946 7.61077 2.59298 6.93208C2.39147 5.77079 2.7391 4.53284 3.63587 3.63607C4.53265 2.73929 5.77063 2.39166 6.93194 2.59319C7.61061 1.62955 8.73179 1 10 1C11.2682 1 12.3893 1.6295 13.068 2.59307C14.2294 2.39145 15.4674 2.73906 16.3643 3.6359C17.2611 4.53274 17.6087 5.77081 17.4071 6.93218C18.3706 7.61087 19 8.73193 19 10Z"
      stroke="#24407E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckBadgeIcon;
