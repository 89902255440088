import React from 'react';
import { Box, Typography } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import PencilSquare from '../../elements/icons/PencilSquareIcon';
import CandidateTrashIcon from '../../elements/icons/CandidateTrashIcon';
import { getStatusChipProps } from './utils';
import StatusChip from '../../elements/statusChips/StatusChip';

export const getColumns = (
  styles: Record<string, any>,
  handleEditCandidate: (id: string) => void,
  handleOpenDeleteModal: (id: number) => void
) => [
  {
    field: 'fullName',
    headerName: 'Name',
    width: 170,
    valueGetter: (params: any) =>
      `${params.row.firstName} ${params.row.lastName}`,
    renderCell: (params: any) => (
      <Typography
        component="a"
        href={`/candidates/edit/${params.id}`}
        target="_self"
        sx={{
          cursor: 'pointer',
          color: '#24407E',
          textDecoration: 'none',
          fontSize: '16px',
        }}
      >
        {params.value}
      </Typography>
    ),
  },
  { field: 'email', headerName: 'E-mail', width: 170 },
  { field: 'phoneNumber', headerName: 'Phone Number', width: 130 },
  {
    field: 'dateOfBirth',
    headerName: 'Date of Birth',
    width: 120,
    valueFormatter: (params: any) => {
      if (params.value) {
        return new Date(params.value).toLocaleDateString('en-GB');
      } else {
        return '';
      }
    },
  },
  {
    field: 'createdOn',
    headerName: 'Created On',
    width: 120,
    valueFormatter: (params: any) => {
      if (params.value) {
        return new Date(params.value).toLocaleDateString('en-GB');
      } else {
        return '';
      }
    },
    sortable: true,
  },
  {
    field: 'completedOn',
    headerName: 'Completed Date',
    width: 150,
    valueGetter: (params: any) => {
      const completedDates = params.row.tests
        .filter((test: any) => test.completedOn)
        .map((test: any) => new Date(test.completedOn))
        .sort((a: number, b: number) => b - a)
        .map((date: Date) => date.toLocaleDateString('en-GB'));

      return completedDates.length > 0 ? completedDates.join(', ') : 'N/A';
    },
    sortable: true,
  },
  {
    field: 'statuses',
    headerName: 'Status',
    width: 500,
    renderCell: (params: any) => (
      <Box sx={styles.statusCell}>
        {params.value.map((status: string, index: number) => {
          const props = getStatusChipProps(status);
          return <StatusChip key={index} {...props} />;
        })}
      </Box>
    ),
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: '',
    flex: 1,
    getActions: (params: any) => [
      <GridActionsCellItem
        icon={<PencilSquare />}
        label="Manage"
        onClick={() => handleEditCandidate(params.id)}
        showInMenu
        sx={styles.actionCellIcon}
      />,
      <GridActionsCellItem
        icon={<CandidateTrashIcon />}
        label="Delete"
        onClick={() => handleOpenDeleteModal(params.id)}
        showInMenu
        sx={styles.removeCellIcon}
      />,
    ],
  },
];
