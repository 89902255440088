import { Candidate } from '../components/pages/Candidates/types';
import {
  CandidateData,
  CandidateResponse,
  CandidateWithTests,
  CsvImportResult,
  DeleteResponse,
} from '../types/candidates';
import baseApi from './baseApi';

export const addEditCandidate = async (
  candidateData: CandidateData
): Promise<CandidateResponse> => {
  try {
    const endpoint = '/business/candidate/AddEditBusinessCandidate';
    const response = await baseApi.post<CandidateResponse>(
      endpoint,
      candidateData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAllCandidates = async (): Promise<Candidate[]> => {
  try {
    const response = await baseApi.get('/business/candidate/GetAllCandidates');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchCandidate = async (
  businessCandidateId: number
): Promise<CandidateWithTests> => {
  const response = await baseApi.get<CandidateWithTests>(
    `/business/candidate/${businessCandidateId}`
  );
  return response.data;
};

export const fetchCandidateTestResults = async (placementTestId: number) => {
  try {
    const response = await baseApi.get(
      `/business/candidate/GetTestResults/${placementTestId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteCandidate = async (id: number): Promise<DeleteResponse> => {
  const response = await baseApi.delete<DeleteResponse>(
    `/business/candidate/${id}`
  );
  return response.data;
};

export const fetchCandidateTestResultsPdf = async (
  placementTestId: number,
  candidateName: string
) => {
  try {
    const response = await baseApi.get(
      `/business/candidate/GetTestResultsPdf/${placementTestId}/${candidateName}`,
      {
        responseType: 'blob',
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const uploadCandidatesCsv = async (
  formData: FormData
): Promise<CsvImportResult> => {
  try {
    const response = await baseApi.post<CsvImportResult>(
      '/business/candidate/UploadCsv',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchImportResults = async (): Promise<CsvImportResult[]> => {
  try {
    const response = await baseApi.get<CsvImportResult[]>(
      '/business/candidate/GetImportResults'
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const downloadImportTemplate = async () => {
  try {
    const response = await baseApi.get(
      '/business/candidate/DownloadImportTemplate',
      {
        responseType: 'blob',
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'CandidateTemplate.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    throw error;
  }
};
