import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import {
  Typography,
  Grid,
  MenuItem,
  Button,
  TextField,
  Link,
  Box,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { getData } from 'country-list';
import { registrationValidationSchema } from './validation';
import {
  registerUser,
  getTermsAndPrivacyUrl,
} from '../../../api/registrationApi';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { useState } from 'react';
import { useRegisterStyles } from './styles';
import { toast } from 'react-toastify';
import { useAuth } from '../../../contexts/AuthContext';

const countryOptions = getData().sort((a, b) => a.name.localeCompare(b.name));

const Register = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const termsAndPrivacyUrl = getTermsAndPrivacyUrl();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const classes = useRegisterStyles();

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleTermsChange = (event: any) => {
    setTermsAccepted(event.target.checked);
  };

  return (
    <Box sx={classes.mainContainer}>
      <Box sx={classes.logoContainer}>
        <Box sx={classes.topLogo}>
          {/* <img
            src="/amber-it-logo.jpg"
            alt="Top Logo"
            style={{ maxWidth: '100%', maxHeight: '100%' }}
          /> */}
        </Box>

        <Box sx={classes.bottomLogo}>
          <img
            src="/ngpt-logo.png"
            alt="Bottom Logo"
            style={{ maxWidth: '100%', maxHeight: '100%' }}
          />
        </Box>
      </Box>

      <Box sx={classes.formContainer}>
        <Typography variant="h5" component="h1" sx={classes.heading}>
          Register school
        </Typography>

        <Formik
          initialValues={{
            tradingName: '',
            legalName: '',
            address: '',
            password: '',
            confirmPassword: '',
            city: '',
            province: '',
            country: '',
            website: '',
            contactPhone: '',
            contactEmail: '',
            legalRepresentative: '',
            legalRepresentativeEmail: '',
          }}
          validationSchema={registrationValidationSchema}
          onSubmit={async (values, actions) => {
            setIsLoading(true);
            try {
              const response = await registerUser(values);
              if (response.token && response.user) {
                login(response.token, response.user);
                navigate('/');
              } else {
                console.error('Unexpected registration response:', response);
              }
            } catch (error) {
              console.error('Registration error:', error);
              toast.error('Registration failed. Please try again.');
              actions.setFieldError(
                'general',
                'Registration failed. Please try again.'
              );
            } finally {
              setIsLoading(false);
            }
          }}
        >
          {({ handleChange, handleBlur, handleSubmit, errors, touched }) => (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Email Address (Login)"
                    type="email"
                    name="contactEmail"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.contactEmail && Boolean(errors.contactEmail)}
                    helperText={touched.contactEmail && errors.contactEmail}
                    fullWidth
                    sx={classes.formField}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Trading name"
                    name="tradingName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.tradingName && Boolean(errors.tradingName)}
                    helperText={touched.tradingName && errors.tradingName}
                    fullWidth
                    sx={classes.formField}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Legal name"
                    name="legalName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.legalName && Boolean(errors.legalName)}
                    helperText={touched.legalName && errors.legalName}
                    fullWidth
                    sx={classes.formField}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Address"
                    name="address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.address && Boolean(errors.address)}
                    helperText={touched.address && errors.address}
                    fullWidth
                    sx={classes.formField}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    label="City"
                    name="city"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.city && Boolean(errors.city)}
                    helperText={touched.city && errors.city}
                    fullWidth
                    sx={classes.formField}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Province (optional)"
                    name="province"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.province && Boolean(errors.province)}
                    helperText={touched.province && errors.province}
                    fullWidth
                    sx={classes.formField}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Country"
                    name="country"
                    defaultValue={''}
                    select
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.country && Boolean(errors.country)}
                    helperText={touched.country && errors.country}
                    fullWidth
                    sx={classes.formField}
                  >
                    {countryOptions.map((option) => (
                      <MenuItem key={option.code} value={option.code}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Contact Phone Number"
                    name="contactPhone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.contactPhone && Boolean(errors.contactPhone)}
                    helperText={touched.contactPhone && errors.contactPhone}
                    fullWidth
                    sx={classes.formField}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Legal representative (optional)"
                    name="legalRepresentative"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.legalRepresentative &&
                      Boolean(errors.legalRepresentative)
                    }
                    helperText={
                      touched.legalRepresentative && errors.legalRepresentative
                    }
                    fullWidth
                    sx={classes.formField}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Legal representative e-mail address (optional)"
                    type="email"
                    name="legalRepresentativeEmail"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.legalRepresentativeEmail &&
                      Boolean(errors.legalRepresentativeEmail)
                    }
                    helperText={
                      touched.legalRepresentativeEmail &&
                      errors.legalRepresentativeEmail
                    }
                    fullWidth
                    sx={classes.formField}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handlePasswordVisibility}
                            edge="end"
                            tabIndex={-1}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={classes.formField}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Repeat Password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    name="confirmPassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.confirmPassword && Boolean(errors.confirmPassword)
                    }
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleConfirmPasswordVisibility}
                            edge="end"
                            tabIndex={-1}
                          >
                            {showConfirmPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={classes.formField}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={termsAccepted}
                      onChange={handleTermsChange}
                      name="termsAccepted"
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="body2">
                      I have read and agree to the{' '}
                      <Link
                        href={termsAndPrivacyUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms and Privacy Policy
                      </Link>
                    </Typography>
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={isLoading || !termsAccepted}
                  sx={classes.submitButton}
                >
                  Register school
                </Button>
              </Grid>

              <Link
                href="mailto:info@jantar.hr"
                variant="body2"
                sx={classes.linkStyle}
              >
                Need help? Feel free to contact us
              </Link>

              <Box sx={{ mt: 2, width: '100%' }}>
                <Typography textAlign="center" sx={{ mb: 2 }}>
                  Already registered?
                </Typography>

                <Button
                  onClick={() => navigate('/login')}
                  fullWidth
                  sx={classes.loginButton}
                >
                  Log in
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default Register;
