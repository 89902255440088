import { SxProps } from '@mui/system';

export const useFileUploadBoxStyles = (): Record<string, SxProps> => ({
  uploadTitle: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#030404',
    marginBottom: '8px',
  },
  uploadBox: {
    display: 'flex',
    alignItems: 'center',
    p: '8px',
    gap: '16px',
    background: '#FFFFFF',
    border: '1.5px dashed #EFF3FA',
    borderRadius: '8px',
  },
  iconBox: {
    p: '32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#EFF3FA',
    borderRadius: '8px',
  },
  icon: {
    fontSize: '24px',
  },
  fileName: {
    mt: '8px',
  },
  infoBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '12px',
  },
  fileFormatText: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#6C7378',
  },
  buttonsBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '8px',
  },
  browseButton: {
    padding: '2px 8px',
    gap: '8px',
    background: '#EFF3FA',
    borderRadius: '4px',
    fontWeight: 700,
    fontSize: '14px',
    boxShadow: 'none',
    lineHeight: '20px',
    textAlign: 'center',
    textTransform: 'none',
    color: '#030404',
    '&:hover': {
      backgroundColor: '#D6DAE1',
      boxShadow: 'none',
    },
  },
  removeButton: {
    padding: '2px 8px',
    gap: '8px',
    background: '#F3D8DC',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
    textTransform: 'none',
    border: 'none',
    color: '#A73737',
    '&:hover': {
      backgroundColor: '#DBB2B5',
      border: 'none',
    },
  },
});
