import { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { toast } from 'react-toastify';
import { useIntegrationTabStyles } from './styles';
import { getApiKey } from '../../../../api/settingsApi';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const codeBlockStyle: React.CSSProperties = {
  backgroundColor: '#f5f5f5',
  padding: '12px',
  borderRadius: '8px',
  fontSize: '14px',
  fontFamily: 'monospace',
};

const bulletListStyle: React.CSSProperties = {
  margin: '0 0 16px 16px',
  listStyleType: 'disc',
  fontSize: '14px',
  color: '#6C7378',
};

const IntegrationTab = () => {
  const [apiKey, setApiKey] = useState<string | null>(null);
  const classes = useIntegrationTabStyles();

  useEffect(() => {
    const fetchApiKey = async () => {
      try {
        const response = await getApiKey();
        setApiKey(response.apiKey);
      } catch (error) {
        console.error('Error fetching API key:', error);
        toast.error('Failed to fetch API key');
      }
    };

    fetchApiKey();
  }, []);

  const handleCopyApiKey = () => {
    if (apiKey) {
      navigator.clipboard.writeText(apiKey).then(
        () => {
          toast.success('API key copied to clipboard!');
        },
        (err) => {
          console.error('Error copying API key: ', err);
          toast.error('Failed to copy API key');
        }
      );
    }
  };

  return (
    <Box sx={classes.container}>
      <Typography variant="h6" sx={classes.title}>
        Integration Settings
      </Typography>
      <TextField
        label="API Key"
        fullWidth
        margin="normal"
        value={apiKey || ''}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleCopyApiKey} aria-label="copy API key">
                <ContentCopyIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={classes.textField}
      />

      <Box sx={{ marginTop: '32px' }}>
        <Typography variant="h6" sx={classes.title}>
          API Integration Guide
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: '#6C7378', marginBottom: '16px' }}
        >
          Follow the instructions below to integrate with our platform using the
          API.
        </Typography>

        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
          Endpoint URL:
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: '16px' }}>
          {process.env.REACT_APP_API_URL}/integration/register-and-send
        </Typography>

        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
          Authentication:
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: '16px' }}>
          Include your API key in the request headers as follows:
        </Typography>
        <pre style={codeBlockStyle}>{`"X-API-Key": "<your-api-key>"`}</pre>

        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 600, marginTop: '16px' }}
        >
          Request Format:
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: '16px' }}>
          Send a POST request with the following JSON payload:
        </Typography>
        <pre style={codeBlockStyle}>
          {`{
  "firstName": "John",
  "lastName": "Doe",
  "email": "johndoe@example.com",
  "phoneNumber": "1234567890", // Optional
  "dateOfBirth": "1990-01-01", // Optional
  "language": "English"
}`}
        </pre>

        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 600, marginTop: '16px' }}
        >
          Supported Languages:
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: '16px' }}>
          You can specify one of the following languages in your request:
        </Typography>
        <ul style={bulletListStyle}>
          <li>English</li>
          <li>Italian</li>
          <li>Spanish</li>
          <li>German</li>
          <li>French</li>
          <li>Russian</li>
        </ul>

        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 600, marginTop: '16px' }}
        >
          Token Balance:
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: '16px' }}>
          Ensure your account has sufficient token balance before attempting to
          send a test. Each test invitation deducts one token from your balance.
        </Typography>

        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 600, marginTop: '16px' }}
        >
          Existing Candidates:
        </Typography>
        <ul style={bulletListStyle}>
          <li>
            If a candidate with the provided email exists, a new candidate won't
            be created. Instead, a test will be sent to the existing candidate.
          </li>
          <li>
            If the candidate already has a pending test for the specified
            language, no new test will be sent, and you will be informed.
          </li>
        </ul>

        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 600, marginTop: '16px' }}
        >
          Expected Responses:
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: '16px' }}>
          A successful request will return:
        </Typography>
        <pre style={codeBlockStyle}>
          {`{
  "message": "Candidate registered and test sent successfully."
}`}
        </pre>

        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 600, marginTop: '16px' }}
        >
          Error Handling:
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: '16px' }}>
          In case of errors, you may receive:
        </Typography>
        <pre style={codeBlockStyle}>
          {`{
  "message": "An error occurred while processing your request.",
  "details": "Detailed error information, if available."
}`}
        </pre>
        <Typography variant="body2" sx={{ marginBottom: '16px' }}>
          Possible status codes:
        </Typography>
        <ul style={bulletListStyle}>
          <li>200: Success</li>
          <li>400: Bad Request</li>
          <li>404: Not Found</li>
          <li>500: Internal Server Error</li>
        </ul>

        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 600, marginTop: '16px' }}
        >
          Notes:
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: '16px' }}>
          Ensure your API key is kept secure and not exposed to unauthorized
          users. Always test the API in a development environment before
          deploying to production.
        </Typography>
      </Box>
    </Box>
  );
};

export default IntegrationTab;
