import {
  RequestPasswordResetData,
  ResetPasswordData,
} from '../types/resetPassword';
import baseApi from './baseApi';

export const requestPasswordReset = async (data: RequestPasswordResetData) => {
  const response = await baseApi.post(
    '/business/change-password/BusinessRequestPasswordReset',
    data
  );
  return response.data;
};

export const resetPassword = async (data: ResetPasswordData) => {
  const response = await baseApi.post(
    '/business/change-password/BusinessSetNewPassword',
    data
  );
  return response.data;
};
