import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import ProtectedRoute from './wrappers/ProtectedRoute';
import { Login } from './components/pages/Login';
import { Register } from './components/pages/Register';
import { Dashboard } from './components/pages/Dashboard';
import { Candidates } from './components/pages/Candidates';
import AddEditCandidate from './components/pages/AddEditCandidates/AddEditCandidates';
import Settings from './components/pages/Settings/Settings';
import { SettingsProvider } from './contexts/SettingsContext';
import PublicRoute from './wrappers/PublicRoute';
import RequestPasswordResetPage from './components/pages/ResetPassword/RequestPasswordReset';
import ResetPasswordPage from './components/pages/ResetPassword/ResetPassword';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />

        <Route
          path="/request-password-reset"
          element={
            <PublicRoute>
              <RequestPasswordResetPage />
            </PublicRoute>
          }
        />
        <Route
          path="/set-new-password/:uid"
          element={
            <PublicRoute>
              <ResetPasswordPage />
            </PublicRoute>
          }
        />

        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/register"
          element={
            <PublicRoute>
              <Register />
            </PublicRoute>
          }
        />

        <Route
          path="/candidates"
          element={
            <ProtectedRoute>
              <Candidates />
            </ProtectedRoute>
          }
        />
        <Route
          path="/candidates/add"
          element={
            <ProtectedRoute>
              <AddEditCandidate />
            </ProtectedRoute>
          }
        />
        <Route
          path="/candidates/edit/:id"
          element={
            <ProtectedRoute>
              <AddEditCandidate />
            </ProtectedRoute>
          }
        />

        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <SettingsProvider>
                <Settings />
              </SettingsProvider>
            </ProtectedRoute>
          }
        />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;
