import { CardDetails } from '../components/elements/paymentForm/types';
import baseApi from './baseApi';

export const createPaymentIntent = async (
  amount: number,
  saveCard: boolean,
  paymentMethodId?: string
) => {
  return await baseApi.post<{ clientSecret: string }>(
    '/business/payment/CreatePaymentIntent',
    {
      amount,
      saveCard,
      paymentMethodId,
    }
  );
};

export const verifyPayment = async (paymentIntentId: string) => {
  return await baseApi.post('/business/payment/VerifyPayment', {
    paymentIntentId,
  });
};

export const fetchSavedPaymentMethods = async (): Promise<CardDetails[]> => {
  try {
    const response = await baseApi.get<CardDetails[]>(
      '/business/payment/ListSavedPaymentMethods'
    );
    return response.data;
  } catch (error) {
    console.error('Failed to fetch saved payment methods:', error);
    return [];
  }
};

export const removePaymentMethod = async (
  paymentMethodId: string
): Promise<void> => {
  try {
    await baseApi.delete(
      `/business/payment/RemovePaymentMethod?paymentMethodId=${paymentMethodId}`
    );
  } catch (error) {
    console.error('Failed to remove payment method:', error);
    throw error;
  }
};

export const setDefaultPaymentMethod = async (paymentMethodId: string) => {
  try {
    await baseApi.post(`/business/payment/SetDefaultPaymentMethod`, {
      paymentMethodId,
    });
  } catch (error) {
    console.error('Failed to set default payment method:', error);
    throw error;
  }
};
