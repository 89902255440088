const CandidatePaperIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.00038 9L1.72461 1.60376C7.23722 3.20514 12.3569 5.73 16.9052 8.99978C12.357 12.2696 7.23728 14.7945 1.72469 16.396L4.00038 9ZM4.00038 9L10.2506 9"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CandidatePaperIcon;
