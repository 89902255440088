import CircularProgress from '@mui/material/CircularProgress';

const LoadingSpinner = ({ small = false }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: small ? 'auto' : '100vh',
    }}
  >
    <CircularProgress />
  </div>
);

export default LoadingSpinner;
