const PencilSquareIcon = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.43306 12.9168L4.69485 9.76231C4.89603 9.25935 5.19728 8.80249 5.58033 8.41945L12.4995 1.50071C13.3279 0.672287 14.6711 0.672289 15.4995 1.50072C16.3279 2.32914 16.3279 3.67229 15.4995 4.50072L8.58033 11.4194C8.19728 11.8025 7.74042 12.1037 7.23746 12.3049L4.08299 13.5667C3.67484 13.73 3.2698 13.3249 3.43306 12.9168Z"
      fill="#24407E"
    />
    <path
      d="M1.5 4.75C1.5 4.05964 2.05964 3.5 2.75 3.5L8 3.5C8.41421 3.5 8.75 3.16421 8.75 2.75C8.75 2.33579 8.41421 2 8 2L2.75 2C1.23122 2 0 3.23122 0 4.75L0 14.25C0 15.7688 1.23122 17 2.75 17L12.25 17C13.7688 17 15 15.7688 15 14.25V9C15 8.58579 14.6642 8.25 14.25 8.25C13.8358 8.25 13.5 8.58579 13.5 9V14.25C13.5 14.9404 12.9404 15.5 12.25 15.5L2.75 15.5C2.05964 15.5 1.5 14.9404 1.5 14.25L1.5 4.75Z"
      fill="#24407E"
    />
  </svg>
);

export default PencilSquareIcon;
