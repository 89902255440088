import { SxProps } from '@mui/system';

export const useDashboardCardStyles = () => ({
  paper: (card: any): SxProps => ({
    padding: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
    background: card.bgCard || '#FFFFFF',
    borderRadius: '12px',
    width: 312,
    height: 92,
    cursor: card.onCardClick ? 'pointer' : 'default',
  }),
  iconBox: (card: any): SxProps => ({
    background: card.bgColorIconBox,
    borderRadius: '12px',
    padding: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 48,
    height: 48,
    marginRight: '24px',
  }),
  title: (card: any): SxProps => ({
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '40px',
    color: card.iconColor,
    marginBottom: '4px',
  }),
  subtitle: (): SxProps => ({
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#6480BC',
  }),
});
