import { FilterStatus } from '../../../types/candidates';
import { Candidate } from './types';
import { isSameDay, parseISO } from 'date-fns';

export const getStatusChipProps = (status: string) => {
  switch (status) {
    case 'Expired':
      return {
        label: 'Expired',
        backgroundColor: '#F3D8DC',
        color: '#C43B52',
        dotColor: '#C43B52',
      };
    case 'Pending':
      return {
        label: 'Pending',
        backgroundColor: '#FEEFD7',
        color: '#E79008',
        dotColor: '#E79008',
      };
    case 'None':
      return {
        label: 'In progress',
        backgroundColor: '#D3E9E6',
        color: '#007D6C',
        dotColor: '#007D6C',
      };
    case 'Completed':
      return {
        label: 'Completed',
        backgroundColor: '#C8E6C9',
        color: '#3C763D',
        dotColor: '#3C763D',
      };
    default:
      return {
        label: status,
        backgroundColor: 'transparent',
        color: '#000000',
        dotColor: 'transparent',
      };
  }
};

export const getVisibleRows = (
  page: number,
  size: number,
  allRows: Candidate[]
) => {
  const start = page * size;
  const end = start + size;
  return allRows.slice(start, end).map((row) => row.id);
};

export const filterCandidates = (
  candidates: Candidate[],
  searchTerm: string,
  filterStatus: FilterStatus,
  selectedDate: Date | null,
  selectedCompletedDate: Date | null
): Candidate[] => {
  const isStatusFiltered = Object.values(filterStatus).some((status) => status);

  return candidates.filter((candidate) => {
    const nameMatch = candidate.firstName
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    const statusMatch = isStatusFiltered
      ? (filterStatus.None && candidate.statuses.length === 0) ||
        candidate.statuses.some(
          (status: string) => filterStatus[status as keyof typeof filterStatus]
        )
      : true;

    const createdDateMatch = selectedDate
      ? isSameDay(parseISO(candidate.createdOn), selectedDate)
      : true;

    const completedDateMatch = selectedCompletedDate
      ? candidate.tests.some(
          (test) =>
            test.completedOn &&
            isSameDay(parseISO(test.completedOn), selectedCompletedDate)
        )
      : true;

    return nameMatch && statusMatch && createdDateMatch && completedDateMatch;
  });
};
