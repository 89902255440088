const EuroIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.25 5.75625C10.667 5.19798 8.83414 5.5519 7.56802 6.81802C5.81066 8.57538 5.81066 11.4246 7.56802 13.182C8.83414 14.4481 10.667 14.802 12.25 14.2437M5.5 8.5H10.75M5.5 11.5H10.75M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
      stroke="#24407E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EuroIcon;
