import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { Typography, Button, TextField, Link, Box } from '@mui/material';
import { loginValidationSchema } from './validation';
import { loginUser } from '../../../api/loginApi';
import { useAuth } from '../../../contexts/AuthContext';
import { useLoginStyles } from './styles';

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const classes = useLoginStyles();

  const handleForgotPasswordClick = () => {
    navigate('/request-password-reset');
  };

  return (
    <Box sx={classes.mainContainer}>
      <Box sx={classes.logoSection}>
        <Box sx={classes.topLogo}>
          {/* <img
            src="/amber-it-logo.jpg"
            alt="Top Logo"
            style={{ maxWidth: '100%', maxHeight: '100%' }}
          /> */}
        </Box>

        <Box sx={classes.bottomLogo}>
          <img
            src="/ngpt-logo.png"
            alt="Bottom Logo"
            style={{ maxWidth: '100%', maxHeight: '100%' }}
          />
        </Box>
      </Box>

      <Box sx={classes.loginFormSection}>
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={loginValidationSchema}
          onSubmit={async (values, actions) => {
            try {
              const { token, user } = await loginUser(values);
              login(token, user);
              navigate('/');
            } catch (error) {
              actions.setFieldError(
                'password',
                'Invalid login credentials. Please try again.'
              );
              console.error('Login error:', error);
              actions.setSubmitting(false);
            }
          }}
        >
          {({ errors, touched, handleSubmit, handleChange, handleBlur }) => (
            <Box
              sx={classes.formStyle}
              component="form"
              onSubmit={handleSubmit}
              noValidate
            >
              <Typography sx={classes.heading}>Log in</Typography>
              <TextField
                name="email"
                type="email"
                label="E-mail"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                fullWidth
                margin="normal"
                sx={classes.inputStyle}
              />
              <TextField
                name="password"
                type="password"
                label="Password"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                fullWidth
                margin="normal"
                sx={classes.inputStyle}
              />
              <Button type="submit" fullWidth sx={classes.loginButton}>
                Log in
              </Button>
              <Typography
                variant="body2"
                sx={{ ...classes.forgotPasswordLink, cursor: 'pointer' }}
                onClick={handleForgotPasswordClick}
              >
                Forgot password?
              </Typography>
              <Typography variant="body1" sx={classes.accessText}>
                Don’t have access to the platform?
              </Typography>
              <Button href="/register" fullWidth sx={classes.registerButton}>
                Register school
              </Button>
            </Box>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default Login;
