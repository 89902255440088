import { Box, Typography } from '@mui/material';

const CustomNoRowsOverlay = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    }}
  >
    <Typography>No data available.</Typography>
  </Box>
);

export default CustomNoRowsOverlay;
