import { SxProps, Theme } from '@mui/system';

export const useSendTestModalStyles = (): Record<string, SxProps<Theme>> => ({
  dialogPaper: {
    width: '100%',
    maxWidth: '327px',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    overflowX: 'hidden',
    alignItems: 'center',
    padding: '24px',
    gap: '24px',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
  },
  dialogTitle: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '40px',
    color: '#24407E',
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'none',
    alignItems: 'center',
    width: '100%',
    padding: '0 24px',
  },
  dialogContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    padding: '0 24px',
    '&:first-of-type': {
      paddingTop: 0,
    },
    borderTop: 'none',
    borderBottom: 'none',
  },
  dialogActions: {
    width: '100%',
    padding: '24px',
    paddingTop: '0',
    margin: '0',
  },
  button: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#FFFFFF',
    backgroundColor: '#24407E',
    borderRadius: '6px',
    textTransform: 'none',
    padding: '10px 16px',
    '&:hover': {
      backgroundColor: '#1e3768',
    },
    '&.Mui-disabled': {
      backgroundColor: '#E0E0E0',
      color: '#9E9E9E',
    },
  },
  radio: {
    '&.Mui-checked': {
      color: '#24407E',
    },
    padding: '10px',
  },
  formControlLabel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '279px',
    margin: 'auto',
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingTop: '4px',
    paddingBottom: '4px',
    background: '#FFFFFF',
    border: '1.5px solid #EFF3FA',
    borderRadius: '8px',
    boxSizing: 'border-box',
    '&:not(:last-child)': {
      marginBottom: '16px',
    },
    '& .MuiTypography-root': {
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '20px',
      color: '#030404',
    },
    '& .MuiRadio-root': {
      order: 2,
      color: '#6C7378',
    },
    '& .MuiFormControlLabel-label': {
      order: 1,
    },
  },
});
