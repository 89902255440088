import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Popover,
  Typography,
} from '@mui/material';
import { FilterList } from '@mui/icons-material';

interface FilterStatus {
  None: boolean;
  Pending: boolean;
  Expired: boolean;
  Completed: boolean;
}

interface StatusFilterProps {
  filterStatus: FilterStatus;
  onFilterChange: (newFilterStatus: FilterStatus) => void;
  styles: Record<string, any>;
}

const StatusFilter: React.FC<StatusFilterProps> = ({
  filterStatus,
  onFilterChange,
  styles,
}) => {
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [filterAnchorEl, setFilterAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [tempFilterStatus, setTempFilterStatus] =
    useState<FilterStatus>(filterStatus);

  const handleOpenFilterModal = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setFilterAnchorEl(event.currentTarget);
    setFilterModalOpen(true);
    setTempFilterStatus({ ...filterStatus });
  };

  const handleCloseFilterModal = () => {
    setFilterModalOpen(false);
    setFilterAnchorEl(null);
    setTempFilterStatus(filterStatus);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTempFilterStatus({
      ...tempFilterStatus,
      [event.target.name]: event.target.checked,
    });
  };

  const applyFilter = () => {
    onFilterChange(tempFilterStatus);
    handleCloseFilterModal();
  };

  const handleResetFilter = () => {
    const resetState = {
      None: false,
      Pending: false,
      Expired: false,
      Completed: false,
    };
    setTempFilterStatus(resetState);
  };

  const countActiveFilters = () => {
    return Object.values(filterStatus).filter(Boolean).length;
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<FilterList />}
        onClick={handleOpenFilterModal}
        sx={{
          ...styles.filterButton,
          ...(Object.values(filterStatus).some((status) => status) && {
            color: '#FFFFFF',
            backgroundColor: '#24407E',
            '&:hover': {
              backgroundColor: '#1e396f',
            },
          }),
        }}
      >
        {`Status${
          countActiveFilters() > 0 ? ` (${countActiveFilters()})` : ''
        }`}
      </Button>
      <Popover
        open={filterModalOpen}
        anchorEl={filterAnchorEl}
        onClose={handleCloseFilterModal}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: styles.popover,
        }}
      >
        <Box sx={styles.filterTopSection}>
          <Typography variant="h6" sx={styles.filterTitle}>
            Filter
          </Typography>
          <Button onClick={handleResetFilter} sx={styles.resetButton}>
            Reset changes
          </Button>
        </Box>
        <FormGroup>
          {Object.keys(filterStatus).map((status) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={tempFilterStatus[status as keyof FilterStatus]}
                  onChange={handleFilterChange}
                  name={status}
                />
              }
              label={status}
              key={status}
            />
          ))}
        </FormGroup>
        <Button
          fullWidth
          variant="contained"
          onClick={applyFilter}
          sx={styles.applyButton}
        >
          Apply filter
        </Button>
      </Popover>
    </>
  );
};

export default StatusFilter;
