import { SxProps } from '@mui/system';

export const useSettingsStyles = (): Record<string, SxProps> => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    mx: 'auto',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '32px',
    lineHeight: '48px',
    color: '#24407E',
  },
  buttonBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '24px',
  },
  resetButton: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '16px',
    color: '#24407E',
    textTransform: 'none',
    background: 'none',
    border: 'none',
    width: '156px',
    height: '36px',
    '&:hover': {
      background: 'none',
    },
  },
  saveButton: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '16px',
    textAlign: 'center',
    color: '#FFFFFF',
    backgroundColor: '#24407E',
    textTransform: 'none',
    width: '79px',
    height: '36px',
    '&:hover': {
      backgroundColor: '#1e396f',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '24px',
    gap: '24px',
    background: '#FFFFFF',
    borderRadius: '8px',
    boxShadow: 'none',
    margin: 'auto',
    width: '100%',
  },
  appBar: {
    width: '100%',
    boxShadow: 'none',
  },
  tabs: {
    '.MuiTabs-flexContainer': {
      justifyContent: 'space-between',
    },
    '.MuiButtonBase-root': {
      flex: '1 1 auto',
      border: '1px solid #24407E',
      '&.Mui-selected': {
        color: '#FFFFFF',
        backgroundColor: '#24407E',
      },
      '&:not(.Mui-selected)': {
        color: '#24407E',
        backgroundColor: '#FFFFFF',
      },
      '&:first-of-type': {
        borderRadius: '6px 0px 0px 6px',
      },
      '&:last-of-type': {
        borderRadius: '0px 6px 6px 0px',
      },
      textTransform: 'none',
      fontWeight: 700,
    },
    '.MuiTabs-indicator': {
      display: 'none',
    },
  },
});
