import { ApiKeyResponse } from '../types/settings';
import baseApi from './baseApi';

export const updateSettings = async (
  settingsData: SettingsFormData
): Promise<void> => {
  await baseApi.put(`/business/settings/`, settingsData);
};

export const changePassword = async (
  oldPassword: string,
  newPassword: string
) => {
  const response = await baseApi.post('/business/settings/change-password', {
    oldPassword,
    newPassword,
  });
  return response.data;
};

export const fetchTransactions = async () => {
  try {
    const response = await baseApi.get('/business/settings/transactions');
    return response.data;
  } catch (error) {
    console.error('Error fetching transactions:', error);
    throw error;
  }
};

export const uploadLogo = async (file: File): Promise<number> => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await baseApi.post(
    '/businessFiles/uploadImageAndResize',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );

  return response.data.resourceId;
};

export const getApiKey = async (): Promise<ApiKeyResponse> => {
  try {
    const response = await baseApi.get<ApiKeyResponse>(
      '/business/settings/api-key'
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching API key:', error);
    throw error;
  }
};
