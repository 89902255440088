import { SxProps, Theme } from '@mui/material/styles';

type DeleteModalStyles = {
  dialogPaper: SxProps<Theme>;
  dialogTitle: SxProps<Theme>;
  dialogContentText: SxProps<Theme>;
  button: {
    primary: SxProps<Theme>;
    secondary: SxProps<Theme>;
  };
  dialogActions: SxProps<Theme>;
};

export const useDeleteModalStyles = (): DeleteModalStyles => ({
  dialogPaper: {
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '8px',
    maxWidth: '400px',
  },
  dialogTitle: {
    fontWeight: 700,
    fontSize: '20px',
    textAlign: 'center',
    color: '#24407E',
    margin: 0,
  },
  dialogContentText: {
    fontWeight: 500,
    fontSize: '14px',
    textAlign: 'center',
    color: '#6C7378',
  },
  button: {
    primary: {
      margin: '8px',
      width: '135.5px',
      height: '44px',
      borderRadius: '8px',
      padding: '12px 20px',
      border: '1px solid #24407E',
      backgroundColor: '#FFFFFF',
      color: '#24407E',
      fontWeight: 700,
      '&:hover': {
        backgroundColor: '#f0f0f0',
      },
    },
    secondary: {
      margin: '8px',
      width: '135.5px',
      height: '44px',
      borderRadius: '8px',
      padding: '12px 20px',
      backgroundColor: '#A73737',
      color: '#FFFFFF',
      fontWeight: 700,
      '&:hover': {
        backgroundColor: '#922929',
      },
    },
  },
  dialogActions: {
    padding: 0,
    margin: '16px 0 0 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    width: '100%',
  },
});
