import { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { requestPasswordReset } from '../../../api/passwordResetApi';
import { RequestPasswordResetData } from '../../../types/resetPassword';
import { useLoginStyles } from '../Login/styles';
import { useNavigate } from 'react-router-dom';

const RequestPasswordResetPage = () => {
  const [email, setEmail] = useState('');
  const classes = useLoginStyles();
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await requestPasswordReset({ email } as RequestPasswordResetData);
      toast.success(
        "We've sent you an email to reset your password. Please check your inbox."
      );
      navigate('/login');
    } catch (error) {
      console.error('Request password reset error:', error);
      toast.error('Error sending reset link. Please try again.');
    }
  };

  return (
    <Box sx={classes.mainContainer}>
      <Box sx={classes.logoSection}>
        <Box sx={classes.topLogo}>
          {/* <img
            src="/amber-it-logo.jpg"
            alt="Top Logo"
            style={{ maxWidth: '100%', maxHeight: '100%' }}
          /> */}
        </Box>

        <Box sx={classes.bottomLogo}>
          <img
            src="/ngpt-logo.png"
            alt="Bottom Logo"
            style={{ maxWidth: '100%', maxHeight: '100%' }}
          />
        </Box>
      </Box>
      <Box sx={classes.loginFormSection}>
        <Box component="form" sx={classes.formStyle} onSubmit={handleSubmit}>
          <Typography sx={classes.heading}>Reset Your Password</Typography>
          <TextField
            label="Email Address"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            fullWidth
            margin="normal"
            sx={classes.inputStyle}
          />
          <Button type="submit" fullWidth sx={classes.loginButton}>
            Send Reset Link
          </Button>
          <Box sx={{ mt: 2, width: '100%' }}>
            <Typography textAlign="center" sx={{ mb: 2 }}>
              Remembered your password?
            </Typography>
            <Button
              onClick={() => navigate('/login')}
              fullWidth
              sx={classes.registerButton}
            >
              Back to Login
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RequestPasswordResetPage;
