import { SxProps, Theme } from '@mui/system';

export const useChangePasswordModalStyles = (): Record<
  string,
  SxProps<Theme>
> => ({
  paperProps: {
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    background: '#FFFFFF',
    borderRadius: '8px',
  },
  dialogTitle: {
    padding: '0px',
    marginBottom: '12px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  titleTypography: {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '40px',
    color: '#24407E',
  },
  closeIconButton: {
    position: 'absolute',
    right: '15px',
    top: '20px',
  },
  dialogContent: {
    padding: '0px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    width: '100%',
  },
  textField: {
    '.MuiInputLabel-root': {
      fontSize: '16px',
      color: '#030404',
    },
    '.MuiInputBase-input': {
      fontSize: '16px',
      color: '#6C7378',
    },
    '.MuiOutlinedInput-root': {
      borderRadius: '8px',
    },
    '.MuiOutlinedInput-notchedOutline': {
      border: '1.5px solid #EFF3FA',
    },
  },
  link: {
    alignSelf: 'center',
    fontSize: '16px',
    color: '#24407E',
    fontWeight: 500,
    textDecoration: 'none',
  },
  dialogActions: {
    padding: '0px',
    flexDirection: 'column',
    width: '100%',
    gap: '16px',
  },
  submitButton: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px 20px',
    background: '#24407E',
    borderRadius: '8px',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 700,
    color: '#FFFFFF',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1e396f',
    },
  },
});
