const DownloadIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 0.5C7.27614 0.5 7.5 0.723858 7.5 1L7.5 8.79289L9.64645 6.64645C9.84171 6.45118 10.1583 6.45118 10.3536 6.64645C10.5488 6.84171 10.5488 7.15829 10.3536 7.35355L7.35355 10.3536C7.15829 10.5488 6.84171 10.5488 6.64645 10.3536L3.64645 7.35355C3.45118 7.15829 3.45118 6.84171 3.64645 6.64645C3.84171 6.45118 4.15829 6.45118 4.35355 6.64645L6.5 8.79289L6.5 1C6.5 0.723858 6.72386 0.5 7 0.5ZM1 9.5C1.27614 9.5 1.5 9.72386 1.5 10L1.5 11.5C1.5 12.0523 1.94772 12.5 2.5 12.5L11.5 12.5C12.0523 12.5 12.5 12.0523 12.5 11.5V10C12.5 9.72386 12.7239 9.5 13 9.5C13.2761 9.5 13.5 9.72386 13.5 10V11.5C13.5 12.6046 12.6046 13.5 11.5 13.5L2.5 13.5C1.39543 13.5 0.5 12.6046 0.5 11.5L0.5 10C0.5 9.72386 0.723858 9.5 1 9.5Z"
      fill="#24407E"
    />
  </svg>
);

export default DownloadIcon;
