import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import PaymentForm from '../../paymentForm/PaymentForm';
import { Input } from '@mui/material';
import { usePaymentModalStyles } from './styles';

const PaymentModal = ({
  open,
  onClose,
  onPaymentSuccess,
}: {
  open: boolean;
  onClose: () => void;
  onPaymentSuccess: () => void;
}) => {
  const classes = usePaymentModalStyles();
  const [amount, setAmount] = useState<number | string>(10);

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/,/g, '');
    if (!value) {
      setAmount('');
    } else {
      const newAmount = parseInt(value, 10);
      if (!isNaN(newAmount)) {
        setAmount(newAmount);
      }
    }
  };

  const incrementAmount = () => {
    setAmount((prevAmount) =>
      typeof prevAmount === 'number' ? prevAmount + 10 : 10
    );
  };

  const decrementAmount = () => {
    setAmount((prevAmount) =>
      typeof prevAmount === 'number' ? Math.max(prevAmount - 10, 10) : 10
    );
  };

  const handleBlur = () => {
    if (typeof amount === 'number' && amount < 10) {
      setAmount(amount);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={classes.modalBox}>
        <Box sx={classes.headerBox}>
          <Typography variant="h6" component="h2" sx={classes.titleTypography}>
            Purchase tokens
          </Typography>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon sx={classes.closeButton} />
          </IconButton>
        </Box>
        <Typography
          variant="caption"
          display="block"
          gutterBottom
          sx={classes.captionTypography}
        >
          Price per token{' '}
          <Box component="span" sx={{ fontWeight: '700', color: '#030404' }}>
            1,00 €
          </Box>
        </Typography>
        <Typography
          variant="caption"
          display="block"
          gutterBottom
          sx={classes.minimumPurchaseTypography}
        >
          Minimum purchase amount{' '}
          <Box component="span" sx={{ fontWeight: '700', color: '#030404' }}>
            10,00 €
          </Box>
        </Typography>
        <Box sx={classes.tokenAmountBox}>
          <IconButton onClick={decrementAmount} sx={classes.iconButton}>
            <RemoveCircleOutlineIcon />
          </IconButton>
          <Input
            value={amount.toString()}
            onChange={handleAmountChange}
            onBlur={handleBlur}
            type="text"
            disableUnderline
            inputProps={{
              min: 0,
              style: {
                textAlign: 'center',
                fontSize: '18px',
                fontWeight: '700',
              },
            }}
            sx={classes.input}
          />
          <IconButton onClick={incrementAmount} sx={classes.iconButton}>
            <AddCircleOutlineIcon />
          </IconButton>
        </Box>
        <PaymentForm
          amount={typeof amount === 'number' ? amount : 0}
          onPaymentSuccess={() => {
            onPaymentSuccess();
            onClose();
          }}
        />
      </Box>
    </Modal>
  );
};

export default PaymentModal;
