import { SxProps, Theme } from '@mui/system';

export const useBillingTabStyles = (): Record<string, SxProps<Theme>> => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    background: '#FFFFFF',
    borderRadius: '8px',
    minWidth: '100%',
  },
  headerTypography: {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '36px',
    color: '#24407E',
  },
  savedPaymentMethodsList: {},
  savedPaymentMethodItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 15px',
    background: '#FFFFFF',
  },
  defaultFlag: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#4CAF50',
    padding: '4px 8px',
    borderRadius: '4px',
    background: '#E8F5E9',
  },
  paymentMethodButton: {
    fontSize: '14px',
    fontWeight: 500,
    textTransform: 'none',
  },
  setDefaultButton: {
    marginRight: '8px',
    borderColor: '#24407E',
    color: '#24407E',
    '&:hover': {
      background: '#EBF0F7',
    },
  },
  removeButton: {
    borderColor: '#A73737',
    color: '#A73737',
    '&:hover': {
      background: '#FFEBEE',
    },
  },
  textField: {
    '.MuiInputLabel-root': {
      color: '#030404',
      fontSize: '16px',
      fontWeight: 500,
    },
    '.MuiOutlinedInput-root': {
      background: '#FFFFFF',
      border: '1.5px solid #EFF3FA',
      borderRadius: '8px',
      '.MuiOutlinedInput-input': {
        color: '#6C7378',
        fontSize: '16px',
        fontWeight: 500,
      },
    },
  },
  listItem: {
    '&:not(:last-child)': {
      borderBottom: (theme: Theme) => `1px solid ${theme.palette.divider}`,
    },
  },
  transactionsHeader: {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '36px',
    color: '#24407E',
    marginBottom: '16px',
  },
});
