import { Typography, Grid, Container, Box } from '@mui/material';
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts';
import { useEffect, useState } from 'react';
import { SharedLayout } from '../SharedLayout';
import { useUserContext } from '../../../contexts/UserContext';
import { fetchDashboardData } from '../../../api/dashboardApi';
import { DashboardData } from '../../../types/dashboard';
import DashboardCards from './DashboardCards/DashboardCards';
import { useDashboardStyles } from './styles';
import LoadingSpinner from '../../elements/loading';
import PaymentModal from '../../elements/modals/PaymentModal/PaymentModal';
import LanguageIcon from '../../elements/icons/LanguageIcon';
import SquareStackedIcon from '../../elements/icons/SquareStackedIcon';

const Dashboard = () => {
  const { user, isLoading } = useUserContext();
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [dashboardData, setDashboardData] = useState<DashboardData | null>(
    null
  );
  const styles = useDashboardStyles();

  useEffect(() => {
    const loadData = async () => {
      if (!isLoading) {
        try {
          const data = await fetchDashboardData();
          setDashboardData(data);
        } catch (error) {
          console.error('Error fetching dashboard data:', error);
        }
      }
    };

    loadData();
  }, [isLoading]);

  const handlePaymentSuccess = () => {
    setPaymentModalOpen(false);
  };

  const levelChartData =
    dashboardData?.completedTestsByLevel.map((item, index) => ({
      name: item.level,
      value: item.count,
      color: ['#6480BC', '#0E182F', '#B7C7EA', '#2D509E', '#1B305F', '#EFF3FA'][
        index % 6
      ],
    })) || [];

  const languageChartData =
    dashboardData?.completedTestsByLanguage.map((item, index) => ({
      name: item.language,
      completed: item.count,
      sent: item.sentCount,
      color: ['#6480BC', '#0E182F', '#B7C7EA', '#2D509E', '#1B305F', '#EFF3FA'][
        index % 6
      ],
    })) || [];

  if (isLoading || !dashboardData) return <LoadingSpinner />;

  return (
    <SharedLayout>
      <Container maxWidth="xl" sx={styles.container}>
        <Typography variant="h4" gutterBottom sx={styles.title}>
          Dashboard
        </Typography>

        <DashboardCards
          user={user}
          dashboardData={dashboardData}
          setPaymentModalOpen={setPaymentModalOpen}
        />

        <Grid container spacing={0} sx={{ mt: 3, mb: 3 }}>
          <Grid item xs={12} md={6} sx={styles.gridItem}>
            <Box sx={styles.gridItemTitleBox}>
              <Box sx={styles.iconBox}>
                <SquareStackedIcon />
              </Box>
              <Box>
                <Typography sx={styles.countTypography}>
                  {dashboardData?.completedTestsCount ?? 'N/A'}
                </Typography>
                <Typography sx={styles.labelTypography}>
                  Completed tests by level
                </Typography>
              </Box>
            </Box>
            {levelChartData.length > 0 ? (
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={levelChartData}
                    dataKey="value"
                    cx="80%"
                    cy="50%"
                    outerRadius="80%"
                  >
                    {levelChartData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            ) : (
              <Typography sx={styles.noDataMessage}>
                No completed tests by level data available.
              </Typography>
            )}

            <Box sx={styles.legendBox}>
              {levelChartData.map((entry, index) => (
                <Box key={index} sx={styles.legendItem}>
                  <Box
                    sx={{
                      ...styles.legendColorBox,
                      backgroundColor: entry.color,
                    }}
                  />
                  <Typography sx={styles.legendNameTypography}>
                    {entry.name}
                  </Typography>
                  <Typography sx={styles.legendValueTypography}>
                    {`${((entry.value / (dashboardData?.completedTestsCount || 1)) * 100).toFixed(1)}%`}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={styles.secondaryGridItem}>
            <Box sx={styles.gridItemTitleBox}>
              <Box sx={styles.iconBox}>
                <LanguageIcon />
              </Box>
              <Box>
                <Typography sx={styles.countTypography}>
                  {languageChartData.length}
                </Typography>
                <Typography sx={styles.labelTypography}>
                  Completed tests by language
                </Typography>
              </Box>
            </Box>
            {languageChartData.length > 0 ? (
              <ResponsiveContainer
                width="100%"
                height="85%"
                style={{ top: '52px', position: 'absolute' }}
              >
                <BarChart
                  data={languageChartData}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend align="right" verticalAlign="top" height={36} />
                  <Bar
                    dataKey="completed"
                    name="Completed"
                    fill="#8EA3D3"
                    barSize={20}
                  />
                  <Bar dataKey="sent" name="Sent" fill="#2D509E" barSize={20} />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <Typography sx={styles.noDataMessage}>
                No completed tests by language data available.
              </Typography>
            )}
          </Grid>
        </Grid>
        <PaymentModal
          open={paymentModalOpen}
          onClose={() => setPaymentModalOpen(false)}
          onPaymentSuccess={handlePaymentSuccess}
        />
      </Container>
    </SharedLayout>
  );
};

export default Dashboard;
