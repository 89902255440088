import React, { ReactNode, useEffect, useState } from 'react';
import {
  Box,
  AppBar,
  Toolbar,
  Button,
  Typography,
  Container,
  MenuItem,
  Menu,
  useTheme,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import PaymentModal from '../../elements/modals/PaymentModal/PaymentModal';
import { useAuth } from '../../../contexts/AuthContext';
import { useUserContext } from '../../../contexts/UserContext';
import ExpandMore from '@mui/icons-material/ExpandMore';
import EuroIcon from '../../elements/icons/EuroIcon';
import { sharedLayoutStyles } from './styles';
import LoadingSpinner from '../../elements/loading';
import ShoppingIcon from '../../elements/icons/ShoppingIcon';

interface SharedLayoutProps {
  children: ReactNode;
}

const SharedLayout = ({ children }: SharedLayoutProps) => {
  const theme = useTheme();
  const classes = sharedLayoutStyles(theme);
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();
  const { user, isLoading } = useUserContext();
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [activeSection, setActiveSection] = useState<string>('dashboard');
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (location.pathname.includes('/settings')) {
      setActiveSection('settings');
    } else {
      setActiveSection('dashboard');
    }
  }, [location.pathname]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePaymentSuccess = () => {
    setPaymentModalOpen(false);
  };

  const navigateTo = (path: string) => {
    navigate(path);
    handleClose();
  };

  const handleLogout = () => {
    logout();
    navigateTo('/login');
  };

  if (isLoading) return <LoadingSpinner />;

  return (
    <Box sx={classes.root}>
      <AppBar position="static" sx={classes.appBar}>
        <Toolbar sx={classes.toolbar}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {user && user.logoUrl ? (
              <img
                src={user.logoUrl}
                alt={user.tradingName + ' Logo'}
                style={{ height: '80px' }}
              />
            ) : null}
          </Typography>
          <Button sx={classes.button} onClick={() => navigate('/')}>
            Dashboard
          </Button>
          <Button sx={classes.button} onClick={() => navigate('/candidates')}>
            Candidates
          </Button>
          <Button sx={classes.tokenButton} startIcon={<EuroIcon />}>
            {`${user?.tokenBalance} Tokens`}
          </Button>
          <Button
            startIcon={<ShoppingIcon />}
            sx={classes.purchaseButton}
            onClick={() => setPaymentModalOpen(true)}
          >
            Purchase Tokens
          </Button>

          <Button
            onClick={handleClick}
            sx={classes.profileButton}
            endIcon={<ExpandMore />}
          >
            {activeSection === 'dashboard' ? user?.tradingName : 'Settings'}{' '}
          </Button>
          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            sx={classes.menu}
          >
            {activeSection !== 'dashboard' && (
              <MenuItem onClick={() => navigateTo('/')} sx={classes.menuItem}>
                {user?.tradingName}
              </MenuItem>
            )}
            {activeSection !== 'settings' && (
              <MenuItem
                onClick={() => navigateTo('/settings')}
                sx={classes.menuItem}
              >
                Settings
              </MenuItem>
            )}
            <MenuItem onClick={handleLogout} sx={classes.signOutMenuItem}>
              Sign Out
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Container maxWidth="xl">{children}</Container>
      <Box component="footer" padding={2} sx={classes.footer}>
        <Typography variant="body2" color="text.secondary">
          {'Copyright © '} Amber IT Solutions d.o.o. {new Date().getFullYear()}
          .
        </Typography>
      </Box>
      <PaymentModal
        open={paymentModalOpen}
        onClose={() => setPaymentModalOpen(false)}
        onPaymentSuccess={handlePaymentSuccess}
      />
    </Box>
  );
};

export default SharedLayout;
