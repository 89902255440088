const ElipsisHorizontalIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.625 10C6.625 10.2071 6.45711 10.375 6.25 10.375C6.04289 10.375 5.875 10.2071 5.875 10C5.875 9.79289 6.04289 9.625 6.25 9.625C6.45711 9.625 6.625 9.79289 6.625 10ZM6.625 10H6.25M10.375 10C10.375 10.2071 10.2071 10.375 10 10.375C9.79289 10.375 9.625 10.2071 9.625 10C9.625 9.79289 9.79289 9.625 10 9.625C10.2071 9.625 10.375 9.79289 10.375 10ZM10.375 10H10M14.125 10C14.125 10.2071 13.9571 10.375 13.75 10.375C13.5429 10.375 13.375 10.2071 13.375 10C13.375 9.79289 13.5429 9.625 13.75 9.625C13.9571 9.625 14.125 9.79289 14.125 10ZM14.125 10H13.75M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
      stroke="#24407E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ElipsisHorizontalIcon;
