import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

export const useCandidateTestsStyles = () => {
  const dataGridSx: SxProps<Theme> = {
    '& .MuiDataGrid-root': {
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      marginBottom: '20px',
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: '#f5f5f5',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0px 16px',
      height: '56px',
      borderBottom: '1px solid #E7ECEE',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      color: '#6C7378',
      textAlign: 'left',
    },
    '& .MuiDataGrid-cell': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '8px 16px',
      gap: '8px',
      height: '64px',
      border: 'none',
      '&:last-of-type': {
        paddingRight: '16px',
      },
    },
    '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
      borderBottom: '1px solid #E7ECEE',
    },
    '& .MuiDataGrid-sortIcon, & .MuiDataGrid-filterIcon': {
      color: '#6C7378',
    },
  };

  const rowSx: SxProps<Theme> = {
    '&.MuiDataGrid-row': {
      backgroundColor: '#6C7378',
      '&:hover': {
        backgroundColor: '#5a6268',
      },
    },
  };

  const statusCell = () => ({
    display: 'flex',
    flexDirection: 'row',
    gap: 1,
    flexWrap: 'wrap',
    alignItems: 'center',
  });

  return { dataGridSx, rowSx, statusCell };
};
