import React, { useState, useEffect, useMemo } from 'react';
import { Box, Button, Paper, TextField, Typography } from '@mui/material';
import SharedLayout from '../SharedLayout/SharedLayout';
import { DataGrid, GridPaginationModel } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { downloadImportTemplate } from '../../../api/candidatesApi';
import {
  sendBulkTestInvite,
  sendTestInvite,
} from '../../../api/placementTestApi';
import { Search } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { DeleteModal } from '../../elements/modals/DeleteModal';
import { useCandidates } from '../../../hooks/useCandidates';
import { useCandidatesTableStyles } from './styles';
import LoadingSpinner from '../../elements/loading';
import CustomNoRowsOverlay from '../../elements/table/CustomNoRowsOverlay';
import ImportResultsTable from '../../elements/table/ImportResultsTable/ImportResultsTable';
import { useUserContext } from '../../../contexts/UserContext';
import { SendTestModal } from '../../elements/modals/SendTestModal';
import { getVisibleRows, filterCandidates } from './utils';
import DateFilter from '../../candidates/DateFilter';
import StatusFilter from '../../candidates/StatusFilter';
import { FilterStatus } from '../../../types/candidates';
import { getColumns } from './columns';
import { useCandidateMutations } from '../../../hooks/useCandidateMutations';
import { useQueryClient } from 'react-query';
import CompletedOnFilter from '../../candidates/CompletedOnFilter';

const CandidatesTable = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const queryClient = useQueryClient();
  const { deleteMutation, uploadMutation } = useCandidateMutations();

  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState<FilterStatus>({
    None: false,
    Pending: false,
    Expired: false,
    Completed: false,
  });
  const [selectedCandidates, setSelectedCandidates] = useState<number[]>([]);
  const [isSendTestModalOpen, setIsSendTestModalOpen] = useState(false);
  const [tokens, setTokens] = useState<number>(user?.tokenBalance ?? 0);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedCompletedDate, setSelectedCompletedDate] =
    useState<Date | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [candidateToDelete, setCandidateToDelete] = useState<number | null>(
    null
  );
  const [selectionModel, setSelectionModel] = useState<number[]>([]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });

  const { data: candidates, isLoading, isError } = useCandidates();
  const styles = useCandidatesTableStyles();

  useEffect(() => {
    if (user?.tokenBalance !== undefined) {
      setTokens(user.tokenBalance);
    }
  }, [user?.tokenBalance]);

  const handleOpenDeleteModal = (candidateId: number) => {
    setCandidateToDelete(candidateId);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteCandidate = () => {
    if (candidateToDelete !== null) {
      deleteMutation.mutate(candidateToDelete);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleEditCandidate = (candidateId: string) => {
    navigate(`/candidates/edit/${candidateId}`);
  };

  const handleCsvUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;
    const file = fileInput.files?.[0];
    if (file) {
      toast.info('Import started. You will be notified once it is finished.');
      const formData = new FormData();
      formData.append('file', file);
      uploadMutation.mutate(formData);
    }
    fileInput.value = '';
  };

  const handleDownloadTemplate = async () => {
    try {
      await downloadImportTemplate();
      toast.success('Template downloaded successfully.');
    } catch (error) {
      toast.error('Failed to download template.');
    }
  };

  const handleSendTests = async (languageId: number) => {
    if (tokens < selectedCandidates.length) {
      toast.error('Not enough tokens to send tests');
      return;
    }

    toast.info(
      'Bulk email process initiated. You will be notified upon completion.'
    );

    const candidatesToSend: any[] = [];
    const candidatesToSkip = [];

    selectedCandidates.forEach((candidateId) => {
      const candidate = candidates!.find((c) => c.id === candidateId);
      if (candidate) {
        const hasPendingTestForLanguage = candidate.statuses.some(
          (status) =>
            status === 'Pending' &&
            candidate.tests.some((test) => test.languageId === languageId)
        );

        if (!hasPendingTestForLanguage) {
          candidatesToSend.push(candidate);
        } else {
          candidatesToSkip.push(candidate);
        }
      }
    });

    if (candidatesToSend.length === 0) {
      toast.info(
        'No candidates to send tests. All selected candidates have pending tests for the selected language.'
      );
      return;
    }

    if (candidatesToSend.length > 1) {
      const bulkInvitePayload = candidatesToSend.map((candidate) => ({
        Email: candidate.email,
        HasGivenMarketingPermission: true,
        LanguageId: languageId,
        BusinessUserId: user!.id,
        BusinessCandidateId: candidate.id,
      }));

      try {
        await sendBulkTestInvite(user!.id, bulkInvitePayload);
        toast.success(
          `Tests sent: ${candidatesToSend.length}, Skipped: ${candidatesToSkip.length}`
        );
        setTokens(tokens - candidatesToSend.length);
      } catch (error) {
        console.error('Error sending bulk invites:', error);
        toast.error('Error sending bulk invites');
      }
    } else if (candidatesToSend.length === 1) {
      const candidate = candidatesToSend[0];
      try {
        await sendTestInvite(
          candidate.email,
          languageId,
          user!.id,
          candidate.id
        );
        toast.success(
          `Test invitation sent: 1, Skipped: ${candidatesToSkip.length}`
        );
        setTokens(tokens - 1);
      } catch (error) {
        toast.error('Error sending invite');
      }
    }

    queryClient.invalidateQueries('candidates');
    queryClient.invalidateQueries('userInfo');
  };

  const handleRowSelection = (newSelection: any) => {
    const visibleRowIds = getVisibleRows(
      paginationModel.page,
      paginationModel.pageSize,
      filteredCandidates
    );

    const newVisibleSelected = newSelection.filter((id: any) =>
      visibleRowIds.includes(id as number)
    );

    setSelectionModel(newVisibleSelected as number[]);

    const isNewSelection = newVisibleSelected.length < selectionModel.length;

    setSelectedCandidates((prevSelected) => {
      if (isNewSelection) {
        return newVisibleSelected as number[];
      } else {
        const newSelectedSet = new Set<number>(prevSelected);
        newVisibleSelected.forEach((id: any) =>
          newSelectedSet.add(id as number)
        );
        return Array.from(newSelectedSet);
      }
    });
  };

  const filteredCandidates = useMemo(
    () =>
      filterCandidates(
        candidates ?? [],
        searchTerm,
        filterStatus,
        selectedDate,
        selectedCompletedDate
      ),
    [candidates, searchTerm, filterStatus, selectedDate, selectedCompletedDate]
  );

  const columns = getColumns(
    styles,
    handleEditCandidate,
    handleOpenDeleteModal
  );

  if (isLoading) return <LoadingSpinner />;
  if (isError) return <div>An error occurred</div>;

  return (
    <SharedLayout>
      <Box sx={styles.container}>
        <Box sx={styles.topRow}>
          <Box sx={styles.titleButtonContainer}>
            <Typography variant="h6" sx={styles.title}>
              Candidates
            </Typography>
            <Button
              variant="contained"
              onClick={() => navigate('/candidates/add')}
              sx={styles.addButton}
            >
              Add
            </Button>
            <input
              accept=".csv, .xlsx, .xls"
              style={{ display: 'none' }}
              id="csv-upload"
              type="file"
              onChange={handleCsvUpload}
            />
            <label htmlFor="csv-upload">
              <Button
                variant="contained"
                component="span"
                sx={styles.importButton}
              >
                Import
              </Button>
            </label>
            <Button
              variant="contained"
              onClick={() => setIsSendTestModalOpen(true)}
              disabled={!selectedCandidates.length}
              sx={styles.sendTestButton}
            >
              Send Tests
            </Button>
          </Box>
          <Box sx={styles.searchFilterContainer}>
            <DateFilter
              selectedDate={selectedDate}
              onDateChange={setSelectedDate}
              styles={styles}
            />

            <CompletedOnFilter
              selectedCompletedDate={selectedCompletedDate}
              onCompletedDateChange={setSelectedCompletedDate}
              styles={styles}
            />

            <StatusFilter
              filterStatus={filterStatus}
              onFilterChange={setFilterStatus}
              styles={styles}
            />

            <TextField
              variant="outlined"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
              sx={styles.searchTextField}
              InputProps={{
                startAdornment: <Search sx={styles.searchIcon} />,
              }}
            />
          </Box>
        </Box>

        <Paper>
          <DataGrid
            rows={filteredCandidates}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            autoHeight
            sx={styles.dataGrid}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            checkboxSelection
            disableRowSelectionOnClick
            pagination
            paginationModel={paginationModel}
            onPaginationModelChange={(newModel) => {
              setPaginationModel(newModel);
              setSelectionModel([]);
              setSelectedCandidates([]);
            }}
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={handleRowSelection}
          />
        </Paper>
      </Box>
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={handleDeleteCandidate}
        title={'Are you sure you want to delete this candidate?'}
        description={
          'This action will permanently delete the candidate and cannot be undone.'
        }
      />

      <Box mt={4}>
        <ImportResultsTable handleDownloadTemplate={handleDownloadTemplate} />
      </Box>

      <SendTestModal
        isOpen={isSendTestModalOpen}
        onClose={() => setIsSendTestModalOpen(false)}
        onSendTest={handleSendTests}
        pendingLanguageIds={[]}
        numberOfCandidates={selectedCandidates.length}
      />
    </SharedLayout>
  );
};

export default CandidatesTable;
